import React from 'react';
import './indicators.css';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

export default function Indicators(props) {
  return (
    <div className='container-indicator'>
      {props.indicatorsInf.map((ind) => (
        <div className='indicator' key={ind.name}>
          <div className='indicator-left'>
            <label>{ind.name}</label>
          </div>
          <div className='indicator-right'>
            <label className="label-number">{ind.number}</label>
            <ind.icon fontSize="large"/>
          </div>
        </div>
      ))}
    </div>
  );
}