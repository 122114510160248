import React from "react";
import "./infoTable.css";
import { Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default function InfoTable(props) {
  const day = props.data;
  return (
    <TableContainer component={Paper}>
      <Table size="small" className="table-info">
        <TableHead>
          <TableRow>
            {props.headers.map((header) => (
              <TableCell>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {day.lunes != null ? (
                <>
                  {day.lunes.hora_inicio} - {day.lunes.hora_fin} <br />{" "}
                  {day.lunes.aula}
                </>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {day.martes != null ? (
                <>
                  {day.martes.hora_inicio} - {day.martes.hora_fin} <br />{" "}
                  {day.martes.aula}
                </>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {day.miercoles != null ? (
                <>
                  {day.miercoles.hora_inicio} - {day.miercoles.hora_fin} <br />{" "}
                  {day.miercoles.aula}
                </>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {day.jueves != null ? (
                <>
                  {day.jueves.hora_inicio} - {day.jueves.hora_fin} <br />{" "}
                  {day.jueves.aula}
                </>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {day.viernes != null ? (
                <>
                  {day.viernes.hora_inicio} - {day.viernes.hora_fin} <br />{" "}
                  {day.viernes.aula}
                </>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {day.sabado != null ? (
                <>
                  {day.sabado.hora_inicio} - {day.sabado.hora_fin} <br />{" "}
                  {day.sabado.aula}
                </>
              ) : (
                "-"
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
