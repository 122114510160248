import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Link as Linkui,
  withStyles,
  LinearProgress,
  CircularProgress,
  IconButton,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  Box,
  TextField,
} from "@material-ui/core";
import EditButton from "../../../Utils/Botones/EditButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Plan.css";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReorderIcon from "@material-ui/icons/Reorder";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import uuid from "uuid/v4";
import NewModal from "../../../Utils/Visuales/NewModal/newModal";
import MateriaContext from "../../../Utils/Providers/materia";
import { getRequest, postRequest } from "../../../Utils/Funciones/requester";
import Logo from "../../../images/logo_uvm.png";
import jsPDF from "jspdf";
import { useSnackbar } from "notistack";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "#b3b3b3",
    width: "30%",
    borderRadius: 10,
    padding: 3,
    margin: "0 10px",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "rgb(231, 36, 0)",
  },
})(LinearProgress);

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiExpansionPanelDetails);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "rgb(231, 36, 0)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgb(231, 36, 0)",
    },
    "& MuiInputBase-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#1b1c20",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(231, 36, 0)",
      },
    },
  },
})(TextField);

export default function Plan(props) {
  const context = useContext(MateriaContext);

  const [loading, setLoading] = useState(true);
  const [temas, setTemas] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [practicas, setPracticas] = useState([]);
  const [sesiones, setSesiones] = useState([]);
  const [progreso, setProgreso] = useState(0);
  const [expanded, setExpanded] = useState(1);
  const [bibliografiaEspecifica, setBibliografiaEspecifica] = useState(false);

  const [planeacionModal, setPlaneacionModal] = useState(false);

  const [bibliografiasTema, setBibliografiasTema] = useState([]);
  const [bibliografiasAgregadas, setBibliografiasAgregadas] = useState([]);

  const [agregarBibliografia, setAgregarBibliografia] = useState(false);
  const [nombreNuevaBibliografia, setNombreNuevaBibliografia] = useState("");
  const [actualBibliografiaSesion, setActualBibliografiaSesion] = useState(0);
  const [indexBibliografiaAEliminar, setIndexBibliografiaAEliminar] =
    useState(-1);
  const [gettingBibliografias, setGettingBibliografias] = useState(false);
  const [todasLasBibliografias, setTodasLasBibliografias] = useState([]);

  const [archivoVisualizar, setArchivoVisualizar] = useState(false);
  const [urlArchivoVisualizar, setUrlArchivoVisualizar] = useState("");
  const [bibliografiaDocumentos, setBibliografiaDocumentos] = useState(
    context.state.bibliografia ? context.state.bibliografia : []
  );
  const [iteracion, setIteracion] = useState(0);
  const refIframe = useRef(null);
  const [obteniendoCriterios, setObteniendoCriterios] = useState(false);

  const [cargandoIFrame, setCargandoIframe] = useState(false);

  const [temasAgregados, setTemasAgregados] = useState({
    temas: [],
    preacticas: [],
    evaluaciones: [],
    temasPorUnidad: [],
  });
  const [bibliografiasSyllabus, setBibliografiasSyllabus] = useState("");
  const [keyIFrame, setKeyIFrame] = useState(0);
  const [urlCompleta, setUrlCompleta] = useState("");
  const [modalDeleteBibliografia, setModalDeleteBibliografia] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (archivoVisualizar) {
      const my_urlCompleta = encodeURIComponent(urlArchivoVisualizar);
      setUrlCompleta(
        `https://docs.google.com/gview?url=${my_urlCompleta}&embedded=true`
      );
      setTimeout(() => {
        setIteracion(1);
      }, 15000);
    }
  }, [archivoVisualizar]);

  useEffect(() => {
    if (iteracion === 0) {
      return;
    }
    if (archivoVisualizar && cargandoIFrame) {
      const my_urlCompleta = encodeURIComponent(urlArchivoVisualizar);
      setUrlCompleta(
        `https://docs.google.com/gview?url=${my_urlCompleta}&embedded=true`
      );
      enqueueSnackbar("Cargando archivo por favor espere", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setKeyIFrame(keyIFrame + 1);
      setTimeout(() => {
        setIteracion(iteracion + 1);
      }, 15000);
    }
  }, [iteracion]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(context.state.planeacionAntigua));

    context.actions.setPlaneacion(JSON.parse(JSON.stringify(data)));
    context.actions.setPlaneacionAntigua(JSON.parse(JSON.stringify(data)));

    context.actions.setChanges(false);

    setTemas(data.unidades);
    setEvaluaciones(data.evaluaciones);
    setPracticas(data.practicas);

    data.sesiones.forEach((sesion) => {
      if (!sesion.bibliografias) {
        if (sesion.temas[0]) {
          if (sesion.temas[0].bibliografias) {
            sesion.bibliografias = sesion.temas[0].bibliografias;
          } else {
            sesion.bibliografias = [];
          }
        } else {
          sesion.bibliografias = [];
        }
      }
    });
    if (bibliografiaDocumentos.length === 0) {
      if (props.url) {
        async function fetchData() {
          let response = await fetch(props.url);
          let data = await response.json();
          context.actions.setBibliografias(data);
          setBibliografiaDocumentos(data);
        }
        fetchData();
      }
    } else {
    }
    setSesiones(data.sesiones);
    getBibliografias2(data.sesiones);
    setLoading(false);
  }, []);

  useEffect(() => {
    context.actions.setPlaneacion({
      unidades: JSON.parse(JSON.stringify(temas)),
      evaluaciones: JSON.parse(JSON.stringify(evaluaciones)),
      practicas: JSON.parse(JSON.stringify(practicas)),
      sesiones: JSON.parse(JSON.stringify(sesiones)),
    });
  }, [temas, evaluaciones, practicas, sesiones]);

  useEffect(() => {
    let idTemasAgregados = [];
    let idTodosLosTemas = [];
    let idParcialesAgregados = [];
    let idPracticasAgregadas = [];
    let temasPorUnidad = {};
    sesiones.forEach((sesion) => {
      sesion.temas.forEach((tema) => {
        if (
          !idTemasAgregados.includes(tema.idOriginal) &&
          tema.tipo === "unidad"
        ) {
          idTemasAgregados.push(tema.idOriginal);
        } else if (
          !idParcialesAgregados.includes(tema.id) &&
          tema.tipo === "evaluación"
        ) {
          idParcialesAgregados.push(tema.id);
        } else if (
          !idPracticasAgregadas.includes(tema.id) &&
          tema.tipo === "practica"
        ) {
          idPracticasAgregadas.push(tema.idOriginal);
        }
      });
    });
    let quantityAllTemas = 0;
    temas.forEach((unit) => {
      quantityAllTemas += unit.content.length;
      let agregadosEstaUnidad = 0;
      unit.content.forEach((tema) => {
        if (idTemasAgregados.includes(tema.id)) {
          agregadosEstaUnidad += 1;
        }
        idTodosLosTemas.push(tema.id);
      });
      temasPorUnidad[String(unit.unit)] = agregadosEstaUnidad;
    });
    let totalElementosParaFecha =
      quantityAllTemas +
      practicas.length +
      idParcialesAgregados.length +
      evaluaciones.length +
      idPracticasAgregadas.length;
    let totalElementosAgregados =
      idTemasAgregados.length +
      idParcialesAgregados.length +
      idPracticasAgregadas.length;
    let avance = (
      (totalElementosAgregados / totalElementosParaFecha) *
      100
    ).toFixed(0);
    setTemasAgregados({
      temas: idTemasAgregados,
      evaluaciones: idParcialesAgregados,
      practicas: idPracticasAgregadas,
      temasPorUnidad,
    });

    setProgreso(avance);
  }, [sesiones]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function downloadDocument(url) {
    setArchivoVisualizar(true);
    setCargandoIframe(true);
    setUrlArchivoVisualizar(url);
  }

  function onDragEnd(result) {
    context.actions.setChanges(true);

    const { source, destination } = result;
    let newSesiones = [...sesiones];
    let newEvaluaciones = [...evaluaciones];
    let newPracticas = [...practicas];
    let removed;

    if (!result.destination) {
      if (source.droppableId.split(":")[0] === "unidad") {
        return;
      } else if (source.droppableId === "evaluaciones") {
        return;
      } else if (source.droppableId === "practicas") {
        return;
      } else {
        let sesionIndex = newSesiones.findIndex(
          (item) => item.id === parseInt(source.droppableId)
        );
        removed = newSesiones[sesionIndex].temas.splice(source.index, 1);
        setSesiones(newSesiones);
        return;
      }
    }

    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId.split(":")[0] === "unidad") {
        let unitIndex = parseInt(source.droppableId.split(":")[1]);
        let newObject = Object.assign(
          {},
          temas[unitIndex].content[source.index]
        );
        removed = [newObject];
        removed[0].idOriginal = removed[0].id;
        removed[0].id = uuid();
      } else if (source.droppableId === "evaluaciones") {
        let newObject = Object.assign({}, evaluaciones[source.index]);
        removed = [newObject];
        newEvaluaciones.splice(source.index, 1);
        setEvaluaciones(newEvaluaciones);
      } else if (source.droppableId === "practicas") {
        let newObject = Object.assign({}, practicas[source.index]);
        removed = [newObject];
        newPracticas.splice(source.index, 1);
        setPracticas(newPracticas);
      } else {
        let sesionIndex = newSesiones.findIndex(
          (item) => item.id === parseInt(source.droppableId)
        );
        removed = newSesiones[sesionIndex].temas.splice(source.index, 1);
      }
      if (destination.droppableId.split(":")[0] === "unidad") {
      } else if (destination.droppableId === "evaluaciones") {
      } else if (destination.droppableId === "practicas") {
      } else {
        let noEstaEnLaLista = true;
        let sesionIndex = newSesiones.findIndex(
          (item) => item.id === parseInt(destination.droppableId)
        );

        for (let i = 0; i < newSesiones[sesionIndex].temas.length; i++) {
          if (
            (newSesiones[sesionIndex].temas[i].idOriginal ===
              removed[0].idOriginal &&
              newSesiones[sesionIndex].temas[i].idOriginal) ||
            newSesiones[sesionIndex].temas[i].id === removed[0].id
          ) {
            noEstaEnLaLista = false;
          }
        }

        if (noEstaEnLaLista) {
          newSesiones[sesionIndex].temas.splice(
            destination.index,
            0,
            ...removed
          );
          setSesiones(newSesiones);
        }

        if (source.droppableId === "evaluaciones") {
        }
      }
    }
  }

  function deleteItem(sessionIndex, index) {
    context.actions.setChanges(true);
    let newSesiones = [...sesiones];
    let removed = newSesiones[sessionIndex].temas.splice(index, 1);
    setSesiones(newSesiones);
    if (removed[0].tipo === "evaluación") {
      let newEvaluaciones = evaluaciones;
      newEvaluaciones.push(removed[0]);
      setEvaluaciones(newEvaluaciones);
    } else if (removed[0].tipo === "practica") {
      let newPracticas = practicas;
      newPracticas.push(removed[0]);
      setPracticas(newPracticas);
    }
  }

  async function saveChanges() {
    let sesiones2 = JSON.parse(JSON.stringify(sesiones));

    let sesionesArray = [];
    for (let i = 0; i < sesiones2.length; i++) {
      let temasArray = [];
      let bibliografiasArray = [];
      for (let j = 0; j < sesiones2[i].temas.length; j++) {
        if (sesiones2[i].temas[j].idOriginal) {
          temasArray.push(sesiones2[i].temas[j].idOriginal);
        } else {
          temasArray.push(sesiones2[i].temas[j].id);
        }
      }
      for (let j = 0; j < sesiones2[i].bibliografias.length; j++) {
        if (sesiones[i].bibliografias[j].id) {
          bibliografiasArray.push(sesiones[i].bibliografias[j].id);
        } else {
          bibliografiasArray.push(sesiones[i].bibliografias[j]);
        }
      }
      sesionesArray.push({
        id: sesiones2[i].id,
        temas: temasArray,
        bibliografias: bibliografiasArray,
      });
    }

    let json = {
      clave_materia: context.state.item.clave,
      clave_docente: context.state.teacherKey,
      sesiones: sesionesArray,
      planeacion: {
        sesiones: sesiones2,
        generales: context.state.generalesCompletos,
      },
    };
    setLoading(true);
    let response = await postRequest("/editar-planeacion", json);
    if (response.s === "OK") {
      setLoading(false);
      context.actions.setPlaneacionAntigua(context.state.planeacion);
      enqueueSnackbar(response.m, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      context.actions.setChanges(false);
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  function onDragEndBibliografia(result) {
    const { source, destination } = result;
    if (!source || !destination) {
      return;
    }
    if (source.droppableId === "lista-bibliografias") {
      let resultadoQuitar = bibliografiasTema.filter(
        (reg, i) => i !== source.index
      );
      if (destination.droppableId === "lista-bibliografias") {
        resultadoQuitar.splice(
          destination.index,
          0,
          bibliografiasTema[source.index]
        );
      } else {
        let resultadoAgregar = [...bibliografiasAgregadas];
        resultadoAgregar.splice(
          destination.index,
          0,
          bibliografiasTema[source.index]
        );
        setBibliografiasAgregadas(resultadoAgregar);
      }
      setBibliografiasTema(resultadoQuitar);
    } else {
      let resultadoQuitar = bibliografiasAgregadas.filter(
        (reg, i) => i !== source.index
      );
      if (destination.droppableId === "bibliografias-agregadas") {
        resultadoQuitar.splice(
          destination.index,
          0,
          bibliografiasAgregadas[source.index]
        );
      } else {
        let resultadoAgregar = [...bibliografiasTema];
        resultadoAgregar.splice(
          destination.index,
          0,
          bibliografiasAgregadas[source.index]
        );
        setBibliografiasTema(resultadoAgregar);
      }
      setBibliografiasAgregadas(resultadoQuitar);
    }
  }

  function deleteItemBibliografiaAgregada(index) {
    let resultadoQuitar = bibliografiasAgregadas.filter((reg, i) => i != index);
    setBibliografiasTema([...bibliografiasTema, bibliografiasAgregadas[index]]);
    setBibliografiasAgregadas(resultadoQuitar);
  }

  function openDeleteBibliografia(index) {
    const idBibliografiaEliminar = bibliografiasTema[index]["id"];
    let canDelete = true;

    sesiones.forEach((reg, i) => {
      reg["bibliografias"].forEach((b) => {
        if (b.id === idBibliografiaEliminar) {
          enqueueSnackbar(
            `Error: bibliografia se utiliza en sesion ${i + 1} con fecha ${
              reg["fecha"]
            }`,
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
          canDelete = false;
        }
      });
    });
    if (canDelete) {
      setIndexBibliografiaAEliminar(index);
      setModalDeleteBibliografia(true);
    }
  }

  async function deleteItemBibliografiasTema() {
    let index = indexBibliografiaAEliminar;
    try {
      setGettingBibliografias(true);
      setModalDeleteBibliografia(false);
      const response = await postRequest(
        "/bibliografia/eliminar-bibliografia",
        { id: bibliografiasTema[index]["id"] }
      );
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        getBibliografias2([]);
      } else {
        setGettingBibliografias(false);
      }
    } catch (e) {
      enqueueSnackbar("Error eliminando bibliografia", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setGettingBibliografias(false);
    }
  }

  async function editarBibliografiaSesion(fila, index) {
    setActualBibliografiaSesion(index);

    setBibliografiasAgregadas([]);
    let text_bibliografias = "";
    Object.keys(bibliografiaDocumentos).forEach((key) => {
      let reg = bibliografiaDocumentos[key];
      text_bibliografias += key + "\n";
      reg.forEach((texto) => {
        text_bibliografias += `    ${texto}\n\n`;
      });
    });
    setBibliografiasSyllabus(text_bibliografias);
    setBibliografiaEspecifica(true);
    getBibliografias(fila);
  }

  async function getBibliografias(fila) {
    let biblioGrafiasViejas = todasLasBibliografias.filter((biblio) =>
      fila.bibliografias.includes(biblio)
    );
    let bibliografiasFiltradas = todasLasBibliografias.filter(
      (biblio) => !fila.bibliografias.includes(biblio)
    );
    setBibliografiasAgregadas(biblioGrafiasViejas);
    setBibliografiasTema(bibliografiasFiltradas);

    setGettingBibliografias(false);
  }

  async function getBibliografias2(addBibliografias) {
    const url = `/bibliografia?clave_materia=${context.state.item.clave}&clave_docente=${context.state.teacherKey}`;
    setGettingBibliografias(true);
    try {
      const response = await getRequest(url);
      if (response.s === "OK") {
        addBibliografias.forEach((reg) => {
          reg["bibliografias"] = response.d.filter((biblio) =>
            reg["bibliografias"].includes(biblio.id)
          );
        });
        setTodasLasBibliografias(response.d);
        setBibliografiasTema(response.d);
        setGettingBibliografias(false);
      } else {
        setGettingBibliografias(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (e) {
      setGettingBibliografias(false);
      enqueueSnackbar("Error obteniendo bibliografias", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  function guardarBibliografiaSesion() {
    let editarSesiones = [...sesiones];
    editarSesiones[actualBibliografiaSesion].bibliografias = [
      ...bibliografiasAgregadas,
    ];
    setSesiones(editarSesiones);
    enqueueSnackbar(
      "Se cambiaron bibliografías especificas, guarda para confirmar cambios",
      {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }
    );
    setBibliografiaEspecifica(false);
  }

  async function handleSendNuevaBibliografia() {
    setAgregarBibliografia(false);
    try {
      setGettingBibliografias(true);
      const response = await postRequest("/bibliografia/nueva", {
        estructura: { name: nombreNuevaBibliografia },
        clave_docente: context.state.teacherKey,
        clave_materia: context.state.item.clave,
        nivel: context.state.item.nivel,
        crn: context.state.item.crn,
      });
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        getBibliografias2([]);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setGettingBibliografias(false);
      }
    } catch (e) {
      enqueueSnackbar("Error agregando bibliografia", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setGettingBibliografias(false);
    }
  }

  function getCriterios() {
    let dataAntigua = JSON.parse(
      JSON.stringify(context.state.criteriosAntiguos)
    );
    if (!dataAntigua) {
      fetchDataCriterios();
    }
  }

  function downloadPlaneacion(dataCriterios) {
    try {
      if (!dataCriterios) {
        getCriterios();
        return;
      }

      var doc = new jsPDF("landscape");

      var img = new Image();
      img.src = Logo;

      doc.addImage(img, "JPEG", 115, 10, 70, 25);
      doc.setFontSize(12);
      doc.text(240, 15, moment().format("DD/MM/YYYY - hh:mm"));
      doc.setFontSize(26);
      doc.text(150, 47, "Planeación", { align: "center" });

      //Tabla Generales
      doc.setFontSize(12);
      doc.text(25, 57, "DATOS GENERALES");
      let contadorAux = 0;
      for (let i = 0; i < context.state.generales.length; i += 2) {
        doc.text(
          25,
          65 + 10 * contadorAux,
          `${context.state.generales[i].name}: ${context.state.generales[i].content}`
        );
        doc.text(
          155,
          65 + 10 * contadorAux,
          `${context.state.generales[i + 1].name}: ${
            context.state.generales[i + 1].content
          }`
        );
        doc.rect(20, 60 + 10 * contadorAux, 130, 10);
        doc.rect(150, 60 + 10 * contadorAux, 130, 10);
        contadorAux++;
      }

      // Headers
      doc.setFontSize(12);
      doc.text(25, 120, "CONTENIDO DEL PROGRAMA");
      doc.text(25, 130, "No.", { align: "center", maxWidth: 20 });
      doc.text(40, 130, "Fecha", { align: "center", maxWidth: 40 });
      doc.text(100, 130, "Temas", { align: "center", maxWidth: 100 });
      doc.text(225, 130, "Bibliografía específica para la sesión", {
        align: "center",
        maxWidth: 100,
      });

      // Tabla
      doc.setFontSize(10);
      let alturaBase = 140;
      let alturaFila = 10;
      let contador = 0;
      let lastPosition = 0;
      for (let i = 0; i < sesiones.length; i++) {
        doc.text(25, alturaBase + alturaFila * (i - contador), `${i + 1}`, {
          align: "center",
          maxWidth: 20,
        });
        doc.text(
          40,
          alturaBase + alturaFila * (i - contador),
          moment(sesiones[i].fecha).format("DD/MM/YYYY"),
          { align: "center", maxWidth: 40 }
        );

        let temas_string = getStringFromTemas(sesiones[i].temas);
        let max_contador = 0;
        if (temas_string.length > 80) {
          doc.text(
            100,
            alturaBase + alturaFila * (i - contador) - 5,
            getStringFromTemas(sesiones[i].temas),
            { align: "center", maxWidth: 100 }
          );
          max_contador = Math.round(temas_string.length / 80);
        } else {
          doc.text(
            100,
            alturaBase + alturaFila * (i - contador),
            getStringFromTemas(sesiones[i].temas),
            { align: "center", maxWidth: 100 }
          );
        }

        let contador_biblio = 0;
        let new_page = false;
        sesiones[i].bibliografias.forEach((bibli, index) => {
          doc.text(
            225,
            alturaBase +
              alturaFila * (i - contador + (index - contador_biblio)),
            bibli["estructura"]["name"],
            { align: "center", maxWidth: 100 }
          );
          if (alturaBase + alturaFila * (i - contador + index) > 180) {
            new_page = true;
            doc.addPage();
            alturaBase = 5;
            contador_biblio = index;
          }
        });

        let heightRect =
          sesiones[i].bibliografias.length + sesiones[i].temas.length || 1;
        doc.rect(
          20,
          1 + alturaBase + alturaFila * (i - contador - 1),
          130,
          10 * heightRect
        );
        doc.rect(
          30,
          1 + alturaBase + alturaFila * (i - contador - 1),
          0,
          10 * heightRect
        );
        doc.rect(
          50,
          1 + alturaBase + alturaFila * (i - contador - 1),
          0,
          10 * heightRect
        );
        doc.rect(
          150,
          1 + alturaBase + alturaFila * (i - contador - 1),
          130,
          10 * heightRect
        );
        contador -=
          sesiones[i].bibliografias.length > max_contador
            ? sesiones[i].bibliografias.length
            : max_contador;
        if (new_page) {
          contador = i - sesiones[i].bibliografias.length;
        }

        if (alturaBase + alturaFila * (i - contador) > 180) {
          doc.addPage();
          alturaBase = 5;
          contador = i;
        }
        lastPosition = alturaBase + alturaFila * (i - contador);
      }

      if (dataCriterios) {
        lastPosition += 15;
        doc.setFontSize(26);
        doc.text(150, lastPosition, `CRITERIOS EVALUACIÓN`, {
          align: "center",
        });

        doc.setFontSize(12);
        alturaBase = lastPosition += 20;
        alturaFila = 10;
        contador = 0;
        Object.keys(dataCriterios)
          .filter((reg) => !reg.includes("FINAL"))
          .forEach((key, i) => {
            let reg = dataCriterios[key];
            doc.text(25, alturaBase + alturaFila * (i - contador - 1), key);
            contador -= 1;
            doc.text(
              150,
              alturaBase + alturaFila * (i - contador - 1),
              "CRITERIOS EVALUACIÓN",
              { align: "center" }
            );
            doc.rect(
              20,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              260,
              10
            );

            if (alturaBase + alturaFila * (i - contador - 1) > 180) {
              doc.addPage();
              alturaBase = 10;
              contador = i;
            }
            contador -= 1;
            Object.keys(reg["criterios_evaluacion"]).forEach((key2) => {
              let reg2 = reg["criterios_evaluacion"][key2];
              doc.text(
                150,
                alturaBase + alturaFila * (i - contador - 1),
                key2,
                { align: "center" }
              );
              doc.rect(
                20,
                alturaBase + 5 + alturaFila * (i - contador - 2),
                260,
                10
              );

              if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                doc.addPage();
                alturaBase = 10;
                contador = i;
              }

              contador -= 1;
              doc.text(
                85,
                alturaBase + alturaFila * (i - contador - 1),
                "Criterios de evaluacion",
                { align: "center" }
              );
              doc.text(
                215,
                alturaBase + alturaFila * (i - contador - 1),
                "Porcentajes",
                { align: "center" }
              );
              doc.rect(
                20,
                alturaBase + 5 + alturaFila * (i - contador - 2),
                130,
                10
              );
              doc.rect(
                150,
                alturaBase + 5 + alturaFila * (i - contador - 2),
                130,
                10
              );

              if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                doc.addPage();
                alturaBase = 10;
                contador = i;
              }

              contador -= 1;
              reg2["criterios"].forEach((criterio, index) => {
                doc.text(
                  25,
                  alturaBase + alturaFila * (i - contador - 1),
                  criterio
                );
                doc.text(
                  215,
                  alturaBase + alturaFila * (i - contador - 1),
                  reg2["porcentajes"][index],
                  { align: "center" }
                );
                doc.rect(
                  20,
                  alturaBase + 5 + alturaFila * (i - contador - 2),
                  130,
                  10
                );
                doc.rect(
                  150,
                  alturaBase + 5 + alturaFila * (i - contador - 2),
                  130,
                  10
                );
                contador -= 1;
                if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                  doc.addPage();
                  alturaBase = 20;
                  contador = i;
                }
              });
              if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                doc.addPage();
                alturaBase = 10;
                contador = i;
              }
            });

            doc.text(
              85,
              alturaBase + alturaFila * (i - contador - 1),
              "Calificación parcial",
              { align: "center" }
            );
            doc.text(
              215,
              alturaBase + alturaFila * (i - contador - 1),
              "100%",
              { align: "center" }
            );
            doc.rect(
              20,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              130,
              10
            );
            doc.rect(
              150,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              130,
              10
            );
            contador -= 1;

            if (alturaBase + alturaFila * (i - contador - 1) > 180) {
              doc.addPage();
              alturaBase = 10;
              contador = i;
            }
            lastPosition = alturaBase + alturaFila * (i - contador - 1);
          });

        alturaBase = lastPosition += 15;
        alturaFila = 10;
        contador = 0;

        Object.keys(dataCriterios)
          .filter((reg) => reg.includes("FINAL"))
          .forEach((key, i) => {
            let reg = dataCriterios[key];
            doc.text(25, alturaBase + alturaFila * (i - contador - 1), key);
            contador -= 1;
            doc.setFillColor(155, 155, 155, 1);
            doc.text(
              150,
              alturaBase + alturaFila * (i - contador - 1),
              "ESTRATEGIA DE EVALUACIÓN PARA EL LOGRO DE LA COMPETENCIA",
              { align: "center" }
            );
            doc.rect(
              20,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              260,
              10
            );
            contador -= 1;
            Object.keys(reg["final"]["estrategia"])
              .filter((reg) => reg != "integracion")
              .forEach((key2) => {
                let reg2 = reg["final"]["estrategia"][key2];
                if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                  doc.addPage();
                  alturaBase = 10;
                  contador = i;
                }

                doc.text(
                  85,
                  alturaBase + alturaFila * (i - contador - 1),
                  key2.replaceAll("_", " ").toUpperCase(),
                  { align: "center" }
                );
                doc.text(
                  215,
                  alturaBase + alturaFila * (i - contador - 1),
                  "Porcentajes",
                  { align: "center" }
                );
                doc.rect(
                  20,
                  alturaBase + 5 + alturaFila * (i - contador - 2),
                  130,
                  10
                );
                doc.rect(
                  150,
                  alturaBase + 5 + alturaFila * (i - contador - 2),
                  130,
                  10
                );
                if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                  doc.addPage();
                  alturaBase = 10;
                  contador = i;
                }
                contador -= 1;
                reg2.forEach((criterio, index) => {
                  doc.text(
                    25,
                    alturaBase + alturaFila * (i - contador - 1),
                    criterio["criterio"]
                  );
                  doc.text(
                    215,
                    alturaBase + alturaFila * (i - contador - 1),
                    criterio["porcentaje"],
                    { align: "center" }
                  );
                  doc.rect(
                    20,
                    alturaBase + 5 + alturaFila * (i - contador - 2),
                    130,
                    10
                  );
                  doc.rect(
                    150,
                    alturaBase + 5 + alturaFila * (i - contador - 2),
                    130,
                    10
                  );
                  contador -= 1;
                  if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                    doc.addPage();
                    alturaBase = 20;
                    contador = i;
                  }
                });
                if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                  doc.addPage();
                  alturaBase = 10;
                  contador = i;
                }
              });
            doc.text(
              85,
              alturaBase + alturaFila * (i - contador - 1),
              "Integración para el logro de la competencia".toUpperCase(),
              { align: "center" }
            );
            doc.text(
              215,
              alturaBase + alturaFila * (i - contador - 1),
              "100%",
              { align: "center" }
            );
            doc.rect(
              20,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              130,
              10
            );
            doc.rect(
              150,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              130,
              10
            );
            contador -= 1;
            if (alturaBase + alturaFila * (i - contador - 1) > 180) {
              doc.addPage();
              alturaBase = 10;
              contador = i;
            }
            doc.text(
              150,
              alturaBase + alturaFila * (i - contador - 1),
              "EVALUACIONES",
              { align: "center" }
            );
            doc.rect(
              20,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              260,
              10
            );
            contador -= 1;
            if (alturaBase + alturaFila * (i - contador - 1) > 180) {
              doc.addPage();
              alturaBase = 10;
              contador = i;
            }
            doc.text(
              85,
              alturaBase + alturaFila * (i - contador - 1),
              "EVALUACIÓN",
              { align: "center" }
            );
            doc.text(
              215,
              alturaBase + alturaFila * (i - contador - 1),
              "Porcentaje",
              { align: "center" }
            );
            doc.rect(
              20,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              130,
              10
            );
            doc.rect(
              150,
              alturaBase + 5 + alturaFila * (i - contador - 2),
              130,
              10
            );
            contador -= 1;
            if (alturaBase + alturaFila * (i - contador - 1) > 180) {
              doc.addPage();
              alturaBase = 10;
              contador = i;
            }
            reg["final"]["integracion"].forEach((reg2) => {
              doc.text(
                20,
                alturaBase + alturaFila * (i - contador - 1),
                reg2["evaluacion"]
              );
              doc.text(
                215,
                alturaBase + alturaFila * (i - contador - 1),
                reg2["porcentaje"],
                { align: "center" }
              );
              doc.rect(
                20,
                alturaBase + 5 + alturaFila * (i - contador - 2),
                130,
                10
              );
              doc.rect(
                150,
                alturaBase + 5 + alturaFila * (i - contador - 2),
                130,
                10
              );
              contador -= 1;
              if (alturaBase + alturaFila * (i - contador - 1) > 180) {
                doc.addPage();
                alturaBase = 20;
                contador = i;
              }
            });
          });
      }

      doc.save(
        `syllabus_planeacion_${context.state.item.clave}_${
          context.state.item.nombre_materia
        }_${context.state.teacherKey}_${
          context.state.item.profesor
        }_${moment().format("DD/MM/YYYY-hh:mm")}.pdf`
      );

      enqueueSnackbar("Planeación descargada con exito", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar("Error descargando planeación", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  async function fetchDataUrlCriterios() {
    if (props.urlCriterios) {
      let response = await fetch(props.urlCriterios);
      let data = await response.json();
      context.actions.setCriterios(JSON.parse(JSON.stringify(data["d"])));
      context.actions.setCriteriosAntiguos(
        JSON.parse(JSON.stringify(data["d"]))
      );
      //setData(data['d'])
      //history.push('/details/criterios/primero')
      setObteniendoCriterios(false);
      downloadPlaneacion(data["d"]);
    } else {
      enqueueSnackbar("No se consiguio criterios de evaluacion", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  async function fetchDataCriterios() {
    setObteniendoCriterios(true);
    const url = `/criterio-evaluacion?clave_materia=${context.state.item.clave}&clave_docente=${context.state.teacherKey}&nivel=${context.state.item.nivel}&crn=${context.state.item.crn}`;
    try {
      const response = await getRequest(url);
      if (response.s === "OK") {
        setObteniendoCriterios(false);
        context.actions.setCriterios(JSON.parse(JSON.stringify(response["d"])));
        context.actions.setCriteriosAntiguos(
          JSON.parse(JSON.stringify(response["d"]))
        );
        downloadPlaneacion(response["d"]);
      } else {
        fetchDataUrlCriterios();
      }
    } catch (error) {
      setObteniendoCriterios(false);
      enqueueSnackbar("Error obteniendo criterios evaluación", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  function getStringFromTemas(temas) {
    let str = "";
    for (let i = 0; i < temas.length; i++) {
      let tema = temas[i].tema.replaceAll(/\t/g, "").split("\r\n");
      str += `${tema} `;
    }
    return str;
  }

  return (
    <div className="container-data-plan">
      {loading ? (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <div className="content-planeacion-top pddb">
            <div className="content-progress">
              <label>Avance de la Planeación:</label>
              <BorderLinearProgress
                variant="determinate"
                color="secondary"
                value={progreso}
              />
              <label>{progreso}%</label>
            </div>
            <EditButton
              style={{ width: 200 }}
              onClick={() => setPlaneacionModal(true)}
            >
              Generar Planeación
            </EditButton>
            {!props.enviado && (
              <EditButton
                className="eddit-buton-save"
                style={{ marginLeft: 10, width: 200 }}
                onClick={saveChanges}
              >
                Guardar
              </EditButton>
            )}
          </div>
          <div className="planeacion-content">
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <div className="content-left">
                <div className="content-expansion-panels">
                  {temas.map((tema, index) => (
                    <ExpansionPanel
                      key={index}
                      square
                      expanded={expanded === tema.unit}
                      onChange={handleChange(tema.unit)}
                      style={
                        expanded === tema.unit
                          ? { height: `${temas.length * 41 + 41}px)` }
                          : null
                      }
                    >
                      <ExpansionPanelSummary>
                        <Typography>
                          Unidad {tema.unit}
                          <span className="cantidad-temas-asignados">
                            {temasAgregados.temasPorUnidad[String(tema.unit)]
                              ? `Asignado ${
                                  temasAgregados.temasPorUnidad[
                                    String(tema.unit)
                                  ]
                                } de ${tema.content.length} temas`
                              : `Asignado 0 de ${tema.content.length} temas`}
                          </span>
                        </Typography>
                        {expanded === tema.unit ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        style={{
                          overflow: "auto",
                          height: "calc(100% - 40px)",
                        }}
                      >
                        {expanded === tema.unit ? (
                          <Droppable
                            isDropDisabled={props.enviado}
                            droppableId={`unidad:${index}`}
                            key={tema.unit}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="panel-content"
                                  style={{
                                    background: snapshot.isDraggingOver
                                      ? "#1b1c20"
                                      : "#ffffff",
                                  }}
                                >
                                  {tema.content.map((item, index) => {
                                    return (
                                      <Draggable
                                        isDragDisabled={props.enviado}
                                        key={item.id}
                                        draggableId={`${item.id}`}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="drag-item"
                                              style={{
                                                borderBottom:
                                                  snapshot.isDragging
                                                    ? "none"
                                                    : "1px solid #ddd",
                                                backgroundColor:
                                                  snapshot.isDragging
                                                    ? "rgb(231, 36, 0)"
                                                    : !temasAgregados.temas.includes(
                                                        item.id
                                                      )
                                                    ? "rgba(255,255,0,0.1)"
                                                    : "#ffffff",
                                                color: snapshot.isDragging
                                                  ? "white"
                                                  : null,
                                                ...provided.draggableProps
                                                  .style,
                                              }}
                                            >
                                              <div className="content-item">
                                                {item.tema}
                                                {!temasAgregados.temas.includes(
                                                  item.id
                                                ) ? (
                                                  <ReorderIcon className="sm-icon" />
                                                ) : (
                                                  <CheckIcon
                                                    style={{
                                                      color: "green",
                                                      margin: "0px",
                                                    }}
                                                    className="m-icon"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        ) : null}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                  <ExpansionPanel
                    square
                    expanded={expanded === "evaluaciones"}
                    onChange={handleChange("evaluaciones")}
                  >
                    <ExpansionPanelSummary>
                      <Typography>
                        Evaluación Estandarizada
                        <span className="cantidad-temas-asignados">
                          {`Asignado ${temasAgregados.evaluaciones.length} de ${
                            evaluaciones.length +
                            temasAgregados.evaluaciones.length
                          } evaluaciones`}
                        </span>
                      </Typography>
                      {expanded === "evaluaciones" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {expanded === "evaluaciones" ? (
                        <Droppable
                          isDropDisabled={props.enviado}
                          droppableId="evaluaciones"
                          key="evaluaciones"
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="panel-content"
                                style={{
                                  background: snapshot.isDraggingOver
                                    ? "#1b1c20"
                                    : "#ffffff",
                                }}
                              >
                                {evaluaciones.map((item, index) => {
                                  return (
                                    <Draggable
                                      isDragDisabled={props.enviado}
                                      key={item.id}
                                      draggableId={`${item.id}`}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="drag-item"
                                            style={{
                                              borderBottom: snapshot.isDragging
                                                ? "none"
                                                : "1px solid #ddd",
                                              backgroundColor:
                                                snapshot.isDragging
                                                  ? "rgb(231, 36, 0)"
                                                  : !temasAgregados.temas.includes(
                                                      item.id
                                                    )
                                                  ? "rgba(255,255,0,0.1)"
                                                  : "#ffffff",
                                              color: snapshot.isDragging
                                                ? "white"
                                                : null,
                                              ...provided.draggableProps.style,
                                            }}
                                          >
                                            <div className="content-item">
                                              {item.tema}
                                              {!temasAgregados.temas.includes(
                                                item.id
                                              ) ? (
                                                <ReorderIcon className="sm-icon" />
                                              ) : (
                                                <CheckIcon
                                                  style={{
                                                    color: "green",
                                                    margin: "0px",
                                                  }}
                                                  className="m-icon"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      ) : null}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    square
                    expanded={expanded === "evaluaciones"}
                    onChange={handleChange("evaluaciones")}
                  >
                    <ExpansionPanelSummary>
                      <Typography>
                        Evaluación Estandarizada
                        <span className="cantidad-temas-asignados">
                          {`Asignado ${temasAgregados.evaluaciones.length} de ${
                            evaluaciones.length +
                            temasAgregados.evaluaciones.length
                          } evaluaciones`}
                        </span>
                      </Typography>
                      {expanded === "practicas" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Droppable
                        isDropDisabled={props.enviado}
                        droppableId="practicas"
                        key="practicas"
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="panel-content"
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "#1b1c20"
                                  : "#ffffff",
                              }}
                            >
                              {evaluaciones.map((item, index) => {
                                return (
                                  <Draggable
                                    isDragDisabled={props.enviado}
                                    key={item.id}
                                    draggableId={`${item.id}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="drag-item"
                                          style={{
                                            borderBottom: snapshot.isDragging
                                              ? "none"
                                              : "1px solid #ddd",
                                            backgroundColor: snapshot.isDragging
                                              ? "rgb(231, 36, 0)"
                                              : !temasAgregados.evaluaciones.includes(
                                                  item.id
                                                )
                                              ? "rgba(255,255,0,0.1)"
                                              : "#ffffff",
                                            color: snapshot.isDragging
                                              ? "white"
                                              : null,
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <div className="content-item">
                                            {item.tema}
                                            {item.url && (
                                              <IconButton
                                                aria-label="download"
                                                onClick={() =>
                                                  downloadDocument(item.url)
                                                }
                                                style={{ padding: 0 }}
                                              >
                                                <GetAppIcon className="sm-icon" />
                                              </IconButton>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    square
                    expanded={expanded === "practicas"}
                    onChange={handleChange("practicas")}
                  >
                    <ExpansionPanelSummary
                      aria-controls="practicas-content"
                      id="practicas-header"
                    >
                      <Typography>
                        Prácticas
                        <span className="cantidad-temas-asignados">
                          {`Asignado ${temasAgregados.practicas.length} de ${
                            practicas.length + temasAgregados.practicas.length
                          } evaluaciones`}
                        </span>
                      </Typography>
                      {expanded === "practicas" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Droppable droppableId="practicas" key="practicas">
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="panel-content"
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "#1b1c20"
                                  : "#ffffff",
                              }}
                            >
                              {practicas.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={`${item.id}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="drag-item"
                                          style={{
                                            borderBottom: snapshot.isDragging
                                              ? "none"
                                              : "1px solid #ddd",
                                            backgroundColor: snapshot.isDragging
                                              ? "rgb(231, 36, 0)"
                                              : !temasAgregados.practicas.includes(
                                                  item.id
                                                )
                                              ? "rgba(255,255,0,0.1)"
                                              : "#ffffff",
                                            color: snapshot.isDragging
                                              ? "white"
                                              : null,
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <div className="content-item">
                                            {item.tema}
                                            {item.url && (
                                              <IconButton
                                                aria-label="download"
                                                onClick={() =>
                                                  downloadDocument(item.url)
                                                }
                                              >
                                                <GetAppIcon className="sm-icon" />
                                              </IconButton>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </div>
              <div className="content-right">
                <TableContainer
                  component={Paper}
                  style={{ height: "100%", overflow: "auto" }}
                >
                  <Table stickyHeader size="small" className="table-info">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={4}
                          style={{ background: "#1b1c20", color: "white" }}
                        >
                          Contenido del programa
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell style={{ width: "70%" }}>Tema</TableCell>
                        <TableCell>Bibliografia</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sesiones.map((item, indexSession) => {
                        return (
                          <TableRow key={indexSession}>
                            <TableCell>{indexSession + 1}</TableCell>
                            <TableCell>
                              {moment(item.fecha).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell style={{ padding: 0 }}>
                              <Droppable
                                isDropDisabled={props.enviado}
                                droppableId={`${item.id}`}
                                key={item.id}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      className="content-item-program"
                                      style={{
                                        background: snapshot.isDraggingOver
                                          ? "#1b1c20"
                                          : "#ffffff",
                                      }}
                                    >
                                      {item.temas.map((item, index) => {
                                        return (
                                          <Draggable
                                            isDragDisabled={props.enviado}
                                            key={item.id}
                                            draggableId={`${item.id}`}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  className="item-program"
                                                  style={{
                                                    maxWidth:
                                                      snapshot.isDragging
                                                        ? 150
                                                        : "100%",
                                                    backgroundColor:
                                                      snapshot.isDragging
                                                        ? "rgb(231, 36, 0)"
                                                        : "#b3b3b3",
                                                    color: snapshot.isDragging
                                                      ? "white"
                                                      : null,
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {item.tema}
                                                    {!props.enviado && (
                                                      <IconButton
                                                        aria-label="delete"
                                                        style={{
                                                          padding: 0,
                                                          float: "right",
                                                        }}
                                                      >
                                                        <CloseIcon
                                                          className="sm-icon"
                                                          onClick={() => {
                                                            deleteItem(
                                                              indexSession,
                                                              index
                                                            );
                                                          }}
                                                        />
                                                      </IconButton>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </TableCell>
                            <TableCell>
                              {item.temas.length > 0 ? (
                                <Linkui
                                  disabled={true}
                                  onClick={() => {
                                    editarBibliografiaSesion(
                                      item,
                                      indexSession
                                    );
                                  }}
                                >
                                  Editar
                                </Linkui>
                              ) : (
                                <a style={{ cursor: "not-allowed" }}>Editar</a>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </DragDropContext>
          </div>
          <NewModal
            open={planeacionModal}
            title="Descargar Planeación"
            handleClose={() => setPlaneacionModal(false)}
            loading={obteniendoCriterios}
            onDownload={() => {
              let dataAntigua = JSON.parse(
                JSON.stringify(context.state.criteriosAntiguos)
              );
              downloadPlaneacion(dataAntigua);
            }}
          >
            <div className="modalPlaneacion">
              <div className="modalPlaneacionHeader">
                <span>{moment().format("DD/MM/YYYY - hh:mm")}</span>
                <img src={Logo} />
                <h4>Planeación</h4>
              </div>
              <div className="modalPlaneacionBody">
                <div className="modalPlaneacionGenerales">
                  <h5>Datos Generales</h5>
                  <Grid container>
                    {context.state.generales.map((row, index) => (
                      <Grid item xs={6} key={index}>
                        <Box className="box-content-filosofia">
                          {row.name}: {row.content}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <div className="modalPlaneacionPrograma">
                  <h5>Contenido del Programa</h5>
                  <Grid container>
                    <Grid item xs={1}>
                      <Box className="box-content-filosofia_title">No.</Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="box-content-filosofia_title">Fecha</Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="box-content-filosofia_title">Tema</Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="box-content-filosofia_title">
                        Bibliografía específica para la sesión
                      </Box>
                    </Grid>
                    {sesiones.map((row, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={1}>
                          <Box className="box-content-filosofia">
                            {index + 1}
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box className="box-content-filosofia">
                            {moment(row.fecha).format("DD/MM/YYYY")}
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box className="box-content-filosofia">
                            {row.temas.map((tema) => `${tema.tema}. `)}
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box className="box-content-filosofia">
                            {row.bibliografias.map((bibliografia, index) => (
                              <p class="biblio-especifica" key={index}>
                                {bibliografia["estructura"]
                                  ? bibliografia["estructura"]["name"]
                                  : bibliografia}
                                .
                              </p>
                            ))}
                          </Box>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
          </NewModal>
          <NewModal
            handleSubmit={() => guardarBibliografiaSesion()}
            open={bibliografiaEspecifica}
            title="Bibliografía"
            handleClose={() => setBibliografiaEspecifica(false)}
          >
            <DragDropContext
              onDragEnd={(result) => onDragEndBibliografia(result)}
            >
              <Grid container style={{ height: "70vh" }}>
                <Grid
                  xs={12}
                  style={{ height: "30vh" }}
                  className="contenedor-listado-estatico"
                >
                  <div class="title-seccion">Bibliografía Syllabus</div>
                  <TextField
                    id="filled-multiline-static"
                    label=""
                    multiline
                    rows={8}
                    disabled
                    defaultValue={bibliografiasSyllabus}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={4} className="contenedor-listado-para-agregar">
                  <div class="title-seccion">
                    Catálogo
                    {!props.enviado && (
                      <button
                        onClick={() => {
                          setNombreNuevaBibliografia("");
                          setAgregarBibliografia(true);
                        }}
                      >
                        +
                      </button>
                    )}
                  </div>
                  {gettingBibliografias ? (
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                      <CircularProgress size={50} />
                    </div>
                  ) : (
                    <Droppable
                      isDropDisabled={props.enviado}
                      droppableId={`lista-bibliografias`}
                      key={11}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="content-item-program"
                            style={{
                              background: snapshot.isDraggingOver
                                ? "#1b1c20"
                                : "#ffffff",
                            }}
                          >
                            {bibliografiasTema.map((bibliografia, index) => {
                              return (
                                <Draggable
                                  isDragDisabled={props.enviado}
                                  key={bibliografia.id}
                                  draggableId={`${bibliografia.id}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="drag-item"
                                        style={{
                                          borderBottom: snapshot.isDragging
                                            ? "none"
                                            : "1px solid #ddd",
                                          backgroundColor: snapshot.isDragging
                                            ? "rgb(231, 36, 0)"
                                            : "#ffffff",
                                          color: snapshot.isDragging
                                            ? "white"
                                            : null,
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <div
                                          title={
                                            bibliografia["estructura"]["name"]
                                          }
                                          className="content-item"
                                        >
                                          {bibliografia["estructura"][
                                            "name"
                                          ].slice(0, 30)}
                                          {bibliografia["estructura"]["name"]
                                            .length > 30
                                            ? "..."
                                            : ""}
                                          {!props.enviado && (
                                            <button
                                              onClick={() => {
                                                openDeleteBibliografia(index);
                                              }}
                                              className="boton-eliminar-bibliografia"
                                            >
                                              -
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  )}
                </Grid>
                <Grid xs={7} className="contenedor-listado-agregado">
                  <div class="title-seccion">Bibliografías de la sesión</div>
                  {gettingBibliografias ? (
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                      <CircularProgress size={50} />
                    </div>
                  ) : (
                    <Droppable
                      isDropDisabled={props.enviado}
                      droppableId={`bibliografias-agregadas`}
                      key={10}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="content-item-program"
                            style={{
                              background: snapshot.isDraggingOver
                                ? "#1b1c20"
                                : "#ffffff",
                            }}
                          >
                            {bibliografiasAgregadas.map(
                              (bibliografia, indexSession) => {
                                return (
                                  <Draggable
                                    isDragDisabled={props.enviado}
                                    key={bibliografia.id}
                                    draggableId={`${bibliografia.id}`}
                                    index={indexSession}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="item-program"
                                          style={{
                                            maxWidth: snapshot.isDragging
                                              ? 150
                                              : "100%",
                                            backgroundColor: snapshot.isDragging
                                              ? "rgb(231, 36, 0)"
                                              : "#b3b3b3",
                                            color: snapshot.isDragging
                                              ? "white"
                                              : null,
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <div
                                            title={
                                              bibliografia["estructura"]["name"]
                                            }
                                            style={{
                                              width: "100%",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {bibliografia["estructura"][
                                              "name"
                                            ].slice(0, 70)}
                                            {bibliografia["estructura"]["name"]
                                              .length > 70
                                              ? "..."
                                              : ""}
                                            {!props.enviado && (
                                              <IconButton
                                                aria-label="delete"
                                                style={{
                                                  padding: 0,
                                                  float: "right",
                                                }}
                                              >
                                                <CloseIcon
                                                  className="sm-icon"
                                                  onClick={() => {
                                                    deleteItemBibliografiaAgregada(
                                                      indexSession
                                                    );
                                                  }}
                                                />
                                              </IconButton>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              }
                            )}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  )}
                </Grid>
              </Grid>
            </DragDropContext>
          </NewModal>
          <NewModal
            handleSubmit={() => {
              handleSendNuevaBibliografia();
            }}
            open={agregarBibliografia}
            title="Nueva Bibliografía"
            handleClose={() => setAgregarBibliografia(false)}
          >
            <div className="contenedor-nueva-bibliografia">
              <TextField
                id="filled-multiline-static"
                label="Ingresar nueva bibliografía"
                multiline
                maxRows={4}
                defaultValue={nombreNuevaBibliografia}
                onChange={(e) => {
                  setNombreNuevaBibliografia(e.target.value);
                }}
                variant="outlined"
              />
            </div>
          </NewModal>
          <NewModal
            handleSubmit={() => {
              deleteItemBibliografiasTema();
            }}
            open={modalDeleteBibliografia}
            title="Eliminar Bibliografía"
            handleClose={() => setModalDeleteBibliografia(false)}
          >
            <div className="contenedor-nueva-bibliografia">
              <h2>¿Seguro que desea eliminar?</h2>
            </div>
          </NewModal>

          <NewModal
            title="Visualizando archivos"
            open={archivoVisualizar}
            handleClose={() => {
              setArchivoVisualizar(false);
              setKeyIFrame(0);
              setUrlArchivoVisualizar("");
              setUrlCompleta("");
              setIteracion(0);
              setCargandoIframe(false);
            }}
            syllabus
            onDownload={urlArchivoVisualizar}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "65vh",
                overflow: "none",
                position: "relative",
              }}
            >
              <div style={{ width: "100%" }}>
                {cargandoIFrame && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: 20,
                      position: "absolute",
                      left: "calc(50% - 25px)",
                    }}
                  >
                    <CircularProgress size={50} />
                  </div>
                )}
                {
                  <iframe
                    title={keyIFrame}
                    loading="lazy"
                    ref={refIframe}
                    key={"12312" + keyIFrame}
                    id="reload-me"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    src={urlCompleta}
                    onLoad={() => {
                      setCargandoIframe(false);
                    }}
                  ></iframe>
                }
              </div>
            </div>
          </NewModal>
        </>
      )}
    </div>
  );
}
