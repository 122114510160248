import React, { MouseEventHandler, CSSProperties } from "react";

import "./style.css";

type Props = {
  id?: string;
  disabled: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  style: CSSProperties;
  children: React.ReactNode;
};

function BlackButton(props: Props) {
  return (
    <button
      id={props.id}
      className={props.disabled ? "BlackButton ButtonCancel" : "BlackButton"}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export default BlackButton;
