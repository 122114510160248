import React from "react";
import NewModal from "./Visuales/NewModal/newModal";
import { CircularProgress } from "@material-ui/core";
export default function ModalSaveChanges({
  confirmModal,
  noSave,
  save,
  continuar,
  onlyClose,
  loading,
}) {
  return (
    <NewModal
      title='Guardar cambios'
      open={confirmModal}
      handleClose={noSave}
      onlyClose={onlyClose}
      handleSubmit={save}
      save
    >
      {loading ? (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <p style={{ textAlign: "center" }}>¿Deseas continuar?</p>
        </>
      )}
    </NewModal>
  );
}
