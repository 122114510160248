import React, { createContext, useState } from 'react'

const MateriaContext = createContext(null)

function MateriaProvider(props){
    const [teacherKey, setTeacherKey] = useState(null)
    const [item, setItem] = useState(null)
    const [planeacionAntigua, setPlaneacionAntigua] = useState(null)
    const [planeacion, setPlaneacion] = useState(null)
    const [changes, setChanges] = useState(false)
    const [changesCriterios,setChangesCriterios] = useState(false)
    const [generales, setGenerales] = useState(null)
    const [filosofia, setFilosofia] = useState(null)
    const [competencia, setCompetencia] = useState(null)
    const [calendario, setCalendario] = useState(null)
    const [materiales, setMateriales] = useState(null)
    const [estrategias, setEstrategias] = useState(null)
    const [criteriosAntiguos,setCriteriosAntiguos] = useState(null)
    const [criterios, setCriterios] = useState(null)
    const [bibliografias,setBibliografias] = useState(null)
    const [generalesCompletos,setGeneralesCompletos] = useState(null)

    function setState(item, teacherKey){
        setTeacherKey(teacherKey)
        setItem(item)
    }

    const value = {
        state: {
            teacherKey,
            item,
            planeacionAntigua,
            planeacion,
            changes,
            changesCriterios,
            generales,
            filosofia,
            competencia,
            calendario,
            materiales,
            estrategias,
            criteriosAntiguos,
            criterios,
            bibliografias,
            generalesCompletos
        },
        actions: {
            setState,
            setPlaneacionAntigua,
            setPlaneacion,
            setChanges,
            setChangesCriterios,
            setGenerales,
            setFilosofia,
            setCompetencia,
            setCalendario,
            setMateriales,
            setEstrategias,
            setCriteriosAntiguos,
            setCriterios,
            setBibliografias,
            setGeneralesCompletos
        }
    }

    return (
        <MateriaContext.Provider value={value}>
            {props.children}
        </MateriaContext.Provider>
    )
}

export default MateriaContext
export { MateriaProvider }