// Función para descargar archivos PDF
export const downloadPDF = async (urlPDF, nombreDocumento, enqueueSnackbar) => {
  try {
    const response = await fetch(urlPDF);
    if (!response.ok) {
      throw new Error(`Error ${response.status}: No se pudo descargar el PDF`);
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = `${nombreDocumento}.pdf`;

    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);

    enqueueSnackbar(`Documento descargado`, {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  } catch (error) {
    enqueueSnackbar(`Error al descargar el documento`, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }
};
