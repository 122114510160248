import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import Logo from "../../images/logo_uvm.png";
import "./login.css";
export default function LoginPage(props) {
  const { login, loading } = props;

  const [rol, setRol] = useState("docente");

  const handleChange = (ev) => {
    setRol(ev.target.value);
  };

  return (
    <div className="form-page">
      <div className="form-container">
        <img src={Logo} alt="Logo" />
        <h1 style={{ color: "black" }}>Syllabus</h1>
        <div className="button-container">
          {loading ? (
            <div className="loader-container">
              <CircularProgress size={40} color="inherit" />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#fff", fontSize: "28px", width: "100%" }}>
                Seleccionar rol:
              </span>
              <select
                style={{
                  width: "370px",
                  height: "50px",
                  borderRadius: "50px",
                  paddingLeft: "25px",
                  fontSize: "28px",
                }}
                onChange={handleChange}
                defaultValue={"docente"}
              >
                <option value="administrador">Administrador</option>
                <option value="docente">Docente</option>
                <option value="evaluador">Evaluador</option>
              </select>
              <button
                disabled={loading}
                onClick={() => login(rol)}
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  height: "50px",
                  border: "none",
                  borderRadius: "50px",
                  fontSize: "28px",
                  width: "280px",
                  marginTop: "50px",
                  cursor: "pointer",
                }}
              >
                Ingresar al sistema
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
