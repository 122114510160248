import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';
import { MemoryRouter as Router } from 'react-router-dom'

ReactDOM.render(
<SnackbarProvider maxSnack={5} preventDuplicate autoHideDuration={2000}>
    <Router><App /></Router>
</SnackbarProvider>, document.getElementById('root'));
