import React, { useState, useEffect, useContext } from "react";
import { Grid, Box } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress'
import MateriaContext from "../../../Utils/Providers/materia"
import { useHistory } from "react-router-dom";
import EditButton from '../../../Utils/Botones/EditButton'
import { getRequest, postRequest } from "../../../Utils/Funciones/requester"
import { useSnackbar } from 'notistack';
function Final({
  name,
  data,
  setData,
  addEvaluacion,
  deleteEvaluacion,
  enviado
}){
  function changeValueInput(seccion,campo,indice,valor){
    let newData = {...data}
    newData['final'][seccion][indice][campo] = valor
    setData(name,newData)
  }

  return (
    <div className="container-data-filosofia">
      <h3 className="upper">{name}</h3>
      <Grid container>
        <Grid item xs={12}>
          <Box className="box-content-filosofia_title upper">
            Estrategia de Evaluación para el logro de la competencia
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">Teórico/Evidencias de conocimiento</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">Porcentaje</Box>
        </Grid>

        {
        data['final']['estrategia'] &&
        data['final']['estrategia']['teorico_evidencias_conocimiento'].map(row => (
          <>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.criterio}</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.porcentaje}</Box>
            </Grid>
          </>
        ))
        }
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title" style={{textAlign:'center'}}>Procedimental/Evidencias de producto</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title" style={{textAlign:'center'}}>Porcentaje</Box>
        </Grid>
        {
        data['final']['estrategia']['procedimental_evidencias_producto'].map(row => (
          <>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.criterio}</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.porcentaje}</Box>
            </Grid>
          </>
        ))
        }
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title" style={{textAlign:'center'}}>Actitudinal/Evidencias de desempeño</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title" style={{textAlign:'center'}}>Porcentaje</Box>
        </Grid>
        {
        data['final']['estrategia']['actitudinal_evidencias'].map(row => (
          <>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.criterio}</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.porcentaje}</Box>
            </Grid>
          </>
        ))
        }
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">Aptitudinal/Evidencias de desempeño</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">Porcentaje</Box>
        </Grid>
        { data['final']['estrategia']['aptitudinal_evidencias'].map(row => {
          if (row.criterio !== ''){
            return (
              <>
                <Grid item xs={12} sm={6}>
                  <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.criterio}</Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="box-content-filosofia" style={{textAlign:'center'}}>{row.porcentaje}</Box>
                </Grid>
              </>
            )
          }
        })}
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">Integración para el logro de la competencia</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">{data['final']['estrategia']['integracion']}</Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Box className="box-content-filosofia_title upper">
            Evaluaciones
            {
              !enviado &&
              <button className="add-row-eval" title="Añadir nueva evaluación" onClick={()=>{addEvaluacion(name,'integracion')}}>+</button>
            }
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">Evaluación</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">Porcentaje</Box>
        </Grid>
        {
        data['final']['integracion'] &&
        data['final']['integracion'].filter(reg=>!reg['evaluacion'].includes('Calificación')).map((row,i) => (
          <>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia">
                <input 
                onChange={(e)=>{
                  changeValueInput('integracion','evaluacion',i,e.target.value)
                }}
                value = {row.evaluacion}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia">
                <input 
                  onChange={(e)=>{
                    changeValueInput('integracion','porcentaje',i,e.target.value+'%')
                  }}
                  value={row.porcentaje.split('%')[0]}
                  type="number"/>
                  {
                    !enviado && 
                    <button 
                      className="del-row-eval" 
                      title="Eliminar evaluación"
                      onClick={()=>{deleteEvaluacion(name,'integracion',i)}}
                    >-</button>
                  }

              </Box>
            </Grid>
          </>
        ))}
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">
            Calificación final
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-content-filosofia_title">100%</Box>
        </Grid>
      </Grid>

    </div>
  )
}

function calcular_total(parcial){
  let total = 0;
  parcial['criterios_evaluacion']['teoricos']['porcentajes'].forEach(porcentaje => {
    total += parseFloat(porcentaje.split('%')[0]) || 0
  });
  parcial['criterios_evaluacion']['practicos']['porcentajes'].forEach(porcentaje => {
    total += parseFloat(porcentaje.split('%')[0]) || 0
  });
  return total + '%'
}


function Parcial({
  name,
  data,
  addRow,
  deleteRow,
  setData,
  enviado
}) {

  function changeValueInput(seccion,campo,indice,valor){
    let newData = {...data}
    newData['criterios_evaluacion'][seccion][campo][indice] = valor
    setData(name,newData)
  }


  return (
    <>
    <div className="container-data-filosofia">
      <h3 className="upper">{name}</h3>
      <table className="table-parcial">
        <thead>
          <tr>
            <th class="box-content-filosofia_title upper" colspan="2">
              {Object.keys(data)[0]}
            </th>
          </tr>
          
          <tr>
            <th class="box-content-filosofia_title" colspan="2">
              Teoría
              {
                !enviado &&
                <button className="add-row-eval" title="Añadir criterio a la teoria" onClick={()=>{addRow(name,"teoricos")}}>+</button>
              }
            </th>
          </tr>
          <tr>
            <th className="box-content-filosofia_title tabla-subtitles">
              Criterios de evaluación 
            </th>
            <th className="box-content-filosofia_title tabla-subtitles">
              Porcentaje
            </th>
          </tr>
          </thead>
          <tbody>
            {
                data['criterios_evaluacion'] &&
                data['criterios_evaluacion']['teoricos']['criterios'].map((criterio,i) => (
                  <tr>
                    <td>
                      <textarea
                        onChange = {(e)=>{
                          changeValueInput(
                            'teoricos',
                            'criterios',
                            i,
                            e.target.value
                          )
                        }}
                        value={criterio}
                      />
                    </td>
                    <td>
                      <input 
                        value={data['criterios_evaluacion']['teoricos']['porcentajes'][i].split('%')[0]}
                        type="number"
                        onChange = {(e)=>{
                          changeValueInput(
                            'teoricos',
                            'porcentajes',
                            i,
                            e.target.value + '%'
                          )
                        }}
                      />
                      {
                        !enviado && 
                        <button className="del-row-eval" title="Eliminar criterio de teoria" onClick={()=>{deleteRow(name,"teoricos",i)}}>-</button>
                      }
                    </td>
                  </tr>
                ))
            }
            <tr>
              <td className="box-content-filosofia_title tabla-subtitles" colspan="2">
                Práctica 
                {
                  !enviado &&
                  <button className="add-row-eval" title="Añadir criterio a practica" onClick={()=>{addRow(name,"practicos")}}>+</button>
                }                
              </td>
            </tr>
            {
              
                data['criterios_evaluacion'] &&
                data['criterios_evaluacion']['practicos']['criterios'].map((criterio,i) => (
                  <tr>
                    <td>
                      <textarea
                        onChange = {(e)=>{
                          changeValueInput(
                            'practicos',
                            'criterios',
                            i,
                            e.target.value
                          )
                        }}
                        value={criterio}
                      />
                    </td>
                    <td>
                      <input 
                        value={data['criterios_evaluacion']['practicos']['porcentajes'][i].split('%')[0]}
                        type="number"
                        onChange = {(e)=>{
                          changeValueInput(
                            'practicos',
                            'porcentajes',
                            i,
                            e.target.value + '%'
                          )
                        }}
                      />
                      {
                        !enviado && 
                        <button className="del-row-eval" title="Eliminar criterio de teoria" onClick={()=>{deleteRow(name,"practicos",i)}}>-</button>
                      }
                    </td>
                  </tr>
                ))
            }
            <tr>
              <td className="box-content-filosofia_title tabla-subtitles">Calificación parcial</td>
              <td className="box-content-filosofia_title tabla-subtitles">
              { 
                calcular_total(data)
              }

              </td>
            </tr>
          </tbody>
      </table>
      <label className="note-pd">
        *Cada parcial se evalua por unidad de aprendizaje completa, por
        resultado de aprendizaje al 100% y no en partes.
      </label>
    </div>
    </>
  );
}

function Evaluacion(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const context = useContext(MateriaContext)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(context.state.criterios ? context.state.criterios : [])
  const history = useHistory()
  useEffect(() => {
    let dataAntigua = JSON.parse(JSON.stringify(context.state.criteriosAntiguos))
    
    context.actions.setCriterios(JSON.parse(JSON.stringify(dataAntigua)))
    context.actions.setCriteriosAntiguos(JSON.parse(JSON.stringify(dataAntigua))) 

    context.actions.setChangesCriterios(false)
    setLoading(true)
    if (!dataAntigua){
        fetchData()
    } else {
      setData(dataAntigua)
      setLoading(false)
    }
  }, [])

  async function fetchDataUrl(){
    if (props.url){
      let response = await fetch(props.url)
      let data = await response.json()
      context.actions.setCriterios(JSON.parse(JSON.stringify(data['d'])))
      context.actions.setCriteriosAntiguos(JSON.parse(JSON.stringify(data['d']))) 
      setData(data['d'])
      history.push('/details/criterios/primero')
      setLoading(false)
    }else{
      enqueueSnackbar("No se consiguio criterios de evaluacion", {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }
      });
    }
  }

  async function fetchData(){
    setLoading(true)
    const url = `/criterio-evaluacion?clave_materia=${context.state.item.clave}&clave_docente=${context.state.teacherKey}&nivel=${context.state.item.nivel}&crn=${context.state.item.crn}`
    try{
      const response = await getRequest(url)
      if(response.s === "OK"){
        setData(response['d'])
        setLoading(false)
        context.actions.setCriterios(JSON.parse(JSON.stringify(response['d'])))
        context.actions.setCriteriosAntiguos(JSON.parse(JSON.stringify(response['d']))) 

      }else{
        fetchDataUrl()
      }
    }catch (error){
      setLoading(false)
      enqueueSnackbar('Error obteniendo criterios evaluación', {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }
      });
    }
  }

  function addRow(name,seccion){
    context.actions.setChangesCriterios(true)
    let newData = {...data}
    newData[name]['criterios_evaluacion'][seccion]['criterios'].push("")
    newData[name]['criterios_evaluacion'][seccion]['porcentajes'].push("0%")
    setData(newData)
    context.actions.setCriterios(newData)
  }
  function deleteRow(name,seccion,index){
    context.actions.setChangesCriterios(true)
    let newData = {...data}
    newData[name]['criterios_evaluacion'][seccion]['criterios'] = newData[name]['criterios_evaluacion'][seccion]['criterios'].filter((_,i)=> i != index)
    newData[name]['criterios_evaluacion'][seccion]['porcentajes'] = newData[name]['criterios_evaluacion'][seccion]['porcentajes'].filter((_,i)=> i != index)
    setData(newData)
    context.actions.setCriterios(newData)

  }

  function setParcial(name,valores){
    context.actions.setChangesCriterios(true)
    let newData = {...data}
    newData[name] = valores
    setData(newData)
    context.actions.setCriterios(newData)
    
  }
  function setFinal(name,valores){
    context.actions.setChangesCriterios(true)
    let newData = {...data}
    newData[name] = valores
    setData(newData)
    context.actions.setCriterios(newData)
  }
  function addEvaluacion(name,seccion){
    context.actions.setChangesCriterios(true)
    let newData = {...data}
    let calculo_final = newData[name]['final'][seccion][newData[name]['final'][seccion].length - 1]
    newData[name]['final'][seccion][newData[name]['final'][seccion].length - 1] = {
      'evaluacion':'',
      'porcentaje':'0%'
    }
    newData[name]['final'][seccion].push(calculo_final)
    setData(newData)
    context.actions.setCriterios(newData)
  }
  function deleteEvaluacion(name,seccion,index){
    let newData = {...data}
    newData[name]['final'][seccion] =newData[name]['final'][seccion].filter((_,i)=> i != index)
    setData(newData)
    context.actions.setCriterios(newData)
  }

  async function guardarEstructura(){
    let can_edit = true

    Object.keys(data).filter(reg => !reg.includes('FINAL')).forEach(key=>{
      let reg = data[key]
      if(calcular_total(reg) != '100%'){
        can_edit = false
      }
    })

    if(!can_edit){
      enqueueSnackbar('Los totales de las evaluaciones parciales deben ser iguales a 100', {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }
      });
      return
    }
    
    let json = {
      clave_materia:context.state.item.clave,
      clave_docente:context.state.teacherKey,
      nivel:context.state.item.nivel,
      crn:context.state.item.crn,
      estructura:data
    }
    setLoading(true)
    try{
      const response = await postRequest('/criterio-evaluacion/editar',json)
      if(response.s === 'OK'){
        context.actions.setChangesCriterios(false)
        context.actions.setCriterios(JSON.parse(JSON.stringify(data)))
        context.actions.setCriteriosAntiguos(JSON.parse(JSON.stringify(data))) 
        setLoading(false)
        enqueueSnackbar(response.m, {
          variant: 'success',
          anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
          }
        });
      }else{
        setLoading(false)
        enqueueSnackbar(response.m, {
          variant: 'error',
          anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
          }
        });
      }
    }catch (error){
      setLoading(false)
      enqueueSnackbar('Error modificando criterios evaluación', {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }
      });
    }
  }
  return (
    <>
    {loading ? null : 
      <div className="content-planeacion-top pddb">
        <div className="content-progress">
        </div>
        <EditButton style={{marginLeft: 10}} onClick={guardarEstructura}>Guardar</EditButton>
      </div>
    }

    <div className="container-data contenedor-evaluaciones">
      {
        loading ?
        <div style={{textAlign: 'center', marginTop: 20}}>
          <CircularProgress size={50} />
        </div> :
        <>
          {
            Object.keys(data).filter(reg => !reg.includes('FINAL')).map(evalua=>(
              <Parcial 
              enviado = {props.enviado}
              name={evalua}
              addRow={addRow}
              deleteRow={deleteRow}
              setData = {setParcial}
              data={data[evalua]}/>
            ))
          } 
          {

            Object.keys(data).filter(reg => reg.includes('FINAL')).map(evalua=>(
              <Final 
              enviado = {props.enviado}
              name = {evalua}
              addEvaluacion={addEvaluacion}
              deleteEvaluacion={deleteEvaluacion}
              data={data[evalua]}
              setData = {setFinal}
              />
            ))

          }
          <div style={{margin:'25px'}}>
          </div>
        </>
      }
    </div>
    </>
  );
}

export default Evaluacion;
