import React, {useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MateriaContext from "../../../Utils/Providers/materia"
import '../details.css';

function DatosGenerales(props) {
  const [loading, setLoading] = useState(true)
  const context = useContext(MateriaContext)
  const [data, setData] = useState(context.state.generales !== null ? context.state.generales : [])

  useEffect(() => {
    if(data.length === 0){
      if(props.url){
        async function fetchData(){
          let response = await fetch(props.url)
          let data = await response.json()
          let json = [
            {
              name: 'Vertical',
              content: data.vertical
            },
            {
              name: 'Licenciatura',
              content: data.licenciatura
            },
            {
              name: 'Academia',
              content: data.academia
            },
            {
              name: 'Asignatura',
              content: data.asignatura
            },
            {
              name: 'Clave de la materia sep/banner',
              content: data.clave_materia
            },
            {
              name: 'Asignatura Antecedente',
              content: data.asignatura_antecedente
            },
            {
              name: 'Modalidad',
              content: data.modalidad
            },
            {
              name: 'Ciclo',
              content: data.ciclo
            },
            {
              name: 'Nombre del profesor',
              content: data.nombre_profesor
            },
            {
              name: 'Correo Electrónico Institucional',
              content: data.correo_institucional
            }
          ]
          context.actions.setGenerales(json)
          setData(json)
          setLoading(false)
        }
  
        fetchData()
      }
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className='container-data'>
      {
        loading ?
        <div style={{textAlign: 'center', marginTop: 20}}>
          <CircularProgress size={50} />
        </div> : 
        <>
          <h3 className='upper'>Datos generales de la asignatura</h3>
          <Grid container>
            {data.map((data) => (
              <Grid xs={6} className='grid-content upper'>
                <Box className='box-content'>
                  <label>{data.name} : </label>
                  <label>{data.content}</label>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      }
    </div>
  )
}

export default DatosGenerales
