import React, { useState, useEffect, useContext } from "react";
import { Grid, Box } from "@material-ui/core";
import "../details.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import MateriaContext from "../../../Utils/Providers/materia"

function Filosofia(props) {
  const [loading, setLoading] = useState(true)
  const context = useContext(MateriaContext)
  const [data, setData] = useState(context.state.filosofia ? context.state.filosofia : [])
  
  useEffect(() => {
    if(data.length === 0){
      if(props.url){
        async function fetchData(){
          let response = await fetch(props.url)
          let data = await response.json()
          context.actions.setFilosofia(data)
          setData(data)
          setLoading(false)
        }
  
        fetchData()
      }
    } else {
      setLoading(false)      
    }
  }, [])

  return (
    <div className="container-data-filosofia">
      {
        loading ?
        <div style={{textAlign: 'center', marginTop: 20}}>
          <CircularProgress size={50} />
        </div> :
        <>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia_title">Misión</Box>
              <Grid item xs={12}>
                <Box className="box-content-filosofia">
                  {data.mision}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia_title">Lema</Box>
              <Grid item xs={12}>
                <Box className="box-content-filosofia">
                  {data.lema}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia_title">Valores</Box>
              <Grid item xs={12}>
                {
                  data.valores.map(row => (
                    <Box className="box-content-filosofia"><b>{row.titulo}:</b> {row.descripcion}</Box>
                  ))
                }
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="box-content-filosofia_title">Principios</Box>
              <Grid item xs={12}>
                {
                  data.principios.map(row => (
                    <Box className="box-content-filosofia"><b>{row.titulo}:</b> {row.descripcion}</Box>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </div>
  );
}

export default Filosofia;
