import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import '../details.css';
import CircularProgress from '@material-ui/core/CircularProgress'
import MateriaContext from "../../../Utils/Providers/materia"

function Competencia(props) {
  const [loading, setLoading] = useState(true)
  const context = useContext(MateriaContext)
  const [data, setData] = useState(context.state.competencia ? context.state.competencia : [])

  useEffect(() => {
    if(data.length === 0){
      if(props.url){
        async function fetchData(){
          let response = await fetch(props.url)
          let data = await response.json()
          context.actions.setCompetencia(data)
          setData(data)
          setLoading(false)
        }
  
        fetchData()
      }
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className='container-data'>
      {
        loading ?
        <div style={{textAlign: 'center', marginTop: 20}}>
          <CircularProgress size={50} />
        </div> :
        <Grid container >
          <Grid item xs={12}>
            <h4 className="bold upper">
              Conocimientos, habilidades, actitudes y destrezas del perfil de egreso que se desarrollan en esta asignatura
              </h4>
            <div className="content-competencias">
              {
                Object.keys(data).map(key=>(
                  <>
                    <label>{key}:</label>
                    <ul>
                      {
                        data[key].map(row => (
                          <li>{row}</li>
                        ))
                      }
                    </ul>
                  </>
                ))
              }
            </div>
          </Grid>
        </Grid>
      }
    </div>
  )
}

export default Competencia
