import React, { useEffect, useState, useContext } from "react";
import CircularProgress from '@material-ui/core/CircularProgress'
import MateriaContext from "../../../Utils/Providers/materia"
import "../details.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

function infoTable(data) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" className="table-info">
        <TableHead>
          <TableRow>
            {Object.keys(data).map((header) => (
              <TableCell>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {Object.keys(data).map((header) => (
              <TableCell>{data[header]}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function leftHeaderTable(data){
  return (
    <TableContainer component={Paper}>
      <Table size="small" className="table-info">
        <TableHead>
          <TableRow>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data).map((header)=>(
            <TableRow>
              <TableCell className="left-headers">
                {header}
              </TableCell>
              <TableCell className="right-cells">
                {data[header]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Calendario(props) {
  const [loading, setLoading] = useState(true)
  const context = useContext(MateriaContext)
  const [dataCompleta,setDataCompleta] = useState(context.state.calendario || [])

  useEffect(() => {
    if (dataCompleta.length == 0){
      if(props.url){
        async function fetchData(){
          let response = await fetch(props.url)
          let data = await response.json()
          setDataCompleta(data)
          context.actions.setCalendario(data)
          setLoading(false)
        }
  
        fetchData()
      }
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className="container-data">
      {
        loading ?
        <div style={{textAlign: 'center', marginTop: 20}}>
          <CircularProgress size={50} />
        </div> :
        <>
        <h3>DISTRIBUCION DE LAS HORAS A LA SEMANA Y TOTALES</h3>
        {infoTable(dataCompleta['DISTRIBUCION DE LAS HORAS A LA SEMANA Y TOTALES'])}
        <h3>MAPA CURRICULAR</h3>
        {infoTable(dataCompleta['MAPA CURRICULAR'])}
        <div styles={{marginBottom:'30px'}}>
        </div>
        </>
      }
    </div>
  );
}

export default Calendario;
