import React, { useState, useEffect, useContext } from "react";
import { TextField } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress'
import '../details.css';
import MateriaContext from "../../../Utils/Providers/materia"

function Materiales(props) {
  const [loading, setLoading] = useState(true)
  const context = useContext(MateriaContext)
  const [data, setData] = useState(context.state.materiales ? context.state.materiales : [])

  useEffect(() => {
    if(data.length === 0){
      if (props.url){
        async function fetchData(){
          let response = await fetch(props.url)
          let data = await response.json()
          context.actions.setMateriales(data.materiales.join('\n'))
          setData(data.materiales.join('\n'))
  
          setLoading(false)
        }
  
        fetchData()
      }
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className="container-data">
      {
        loading ?
        <div style={{textAlign: 'center', marginTop: 20}}>
          <CircularProgress size={50} />
        </div> :
        <>
          <h3>
            Materiales que el estudiante requiere para realizar las actividades
          </h3>
          <TextField
            id="outlined-multiline-static"
            label="Materiales"
            multiline
            fullWidth
            rows="4"
            defaultValue={data}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </>
      }
    </div>
  );
}

export default Materiales;
