import React from 'react';
import './contenedor.css';

export default function Contenedor(props) {
  return (
    <main>
      <div className='contenedor'>
        {props.children}
      </div>
    </main>
  );
}