import React, { useState } from "react";
import Logo from "../../../images/logo_uvm.png";
import "./header.css";
import { CircularProgress } from "@material-ui/core";
import { getRequest } from "../../Funciones/requester";
import AvatarIcon from "../../../images/AvatarIcon";
import NewModal from "../NewModal/newModal";
import HelpIcon from "@material-ui/icons/Help";
import samplePDF from "../../../images/sample.pdf";

export default function Header(props) {
  const [modalSesion, setModalSesion] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalAyudaIsOpen, setModalAyudaIsOpen] = useState(false);

  async function logout() {
    setModalLoading(true);

    let response = await getRequest("/logout");
    if (response.s === "OK") {
      localStorage.removeItem("token_syllabus");
      window.location.reload();
    }
  }

  return (
    <>
      <header>
        <div className='logo'>
          <img src={Logo} alt='Logo UVM' />
        </div>
        <div className='page-title'>
          <h1>Plataforma de registro de tema syllabus</h1>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            flexDirection: "column",
          }}
          onClick={() => setModalAyudaIsOpen(true)}
        >
          <HelpIcon
            style={{
              width: "50px",
              height: "50px",
            }}
          />
          Ayuda
        </div>
        <div className='user-info'>
          <div>
            <p className='user-name'>{props.username}</p>
            <p className='user-rol'>Profesor</p>
          </div>
          {props.image ? (
            <img src={props.img} alt='avatar' />
          ) : (
            <AvatarIcon onClick={() => setModalSesion(true)} />
          )}
          <NewModal
            title='Cerrar sesión'
            open={modalSesion}
            handleClose={() => setModalSesion(false)}
            handleSubmit={logout}
          >
            <div
              style={{
                height: "80px",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              {modalLoading ? (
                <CircularProgress />
              ) : (
                <p style={{ textAlign: "center" }}>
                  ¿Está seguro de que desea cerrar sesión?
                </p>
              )}
            </div>
          </NewModal>
        </div>
      </header>

      <NewModal
        title='AYUDA'
        open={modalAyudaIsOpen}
        handleClose={() => setModalAyudaIsOpen(false)}
        disableAccept
        hideSubmit
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "65vh",
            overflow: "none",
            position: "relative",
          }}
        >
          <iframe
            title='AYUDA PDF'
            src={`https://syllabus-docs.s3.amazonaws.com/Sydi-Documento+manual+docente.pdf?t=${parseInt(
              Math.random() * 100 // Genera un número aleatorio para evitar el cache
            )}#toolbar=0&navpanes=0&scrollbar=0`}
            frameBorder='0'
            style={{
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div>
      </NewModal>
    </>
  );
}
