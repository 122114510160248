import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoginPage from "./Vistas/LoginPage/login";
import Header from "./Utils/Visuales/Header/header";
import {
  postRequest,
  version,
  url_script_ayuda,
  getRequest,
} from "./Utils/Funciones/requester";
import Footer from "./Utils/Visuales/Footer/footer";
import Dashboard from "./Vistas/Dashboard";
import Details from "./Vistas/Details/details";
import { MateriaProvider } from "./Utils/Providers/materia";
import { IndicadoresProvider } from "./Utils/Providers/indicadores";
import "./App.css";

export default function App() {
  const [logged, setLogged] = useState(false);
  const [logging, setLogging] = useState(false);
  const [nombre, setNombre] = useState("Usuario");
  const [image] = useState("");

  const [teacherKey, setTeacherKey] = useState("");

  let history = useHistory();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = url_script_ayuda;
    script.id = "ze-snippet";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      setTimeout(() => {
        if (window.zE) {
          window.zE("webWidget", "setLocale", "es");
        }
      }, 500);
    };
  }, []);

  useEffect(() => {
    (async function checkLogin() {
      let token = localStorage.getItem("token_syllabus");
      if (token) {
        let myUrl = window.location.href;
        let inicioParametros = myUrl.indexOf("?");
        let newUrl = myUrl.substr(0, inicioParametros);

        window.history.pushState("obj", "newtitle", newUrl);

        setLogging(true);
        try {
          let response = await postRequest("/login");
          if (response.s === "OK") {
            if (
              (response.d.rol === "administrador" ||
                response.d.rol === "evaluador") &&
              process.env.NODE_ENV === "production"
            ) {
              localStorage.setItem("token_syllabus_admin", token);
              if (response["d"]["redirect"]) {
                if (response["d"]["redirect"] !== window.location.href) {
                  window.location.href = response["d"]["redirect"];
                  return;
                }
              }
              if (!window.location.pathname.includes("/admin")) {
                if (window.location.pathname.at(-1) === "/") {
                  window.location.href = window.location.pathname + "admin";
                } else {
                  window.location.href = window.location.pathname + "/admin";
                }
              }
            } else {
              setTeacherKey(response.d.clave);
              setLogging(false);
              setLogged(true);
              setNombre(response.d.nombre);
              history.push("/dashboard/licenciatura");
            }
          } else {
            let myUrl = window.location.href;
            let inicioParametros = myUrl.indexOf("?");
            let newUrl = myUrl.substr(0, inicioParametros);

            window.history.pushState("obj", "newtitle", newUrl);
            setTimeout(() => {
              window.location.reload();
            }, 2000);

            window.localStorage.removeItem("token_syllabus");
            setLogging(false);
          }
        } catch (error) {
          setLogging(false);
          window.location.reload();
        }
      } else {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get("code");
        const mode = urlParams.get("mode");
        const mail = urlParams.get("mail");
        const rolParam = urlParams.get("rol");
        if (rolParam !== null) {
          window.localStorage.setItem("rol", rolParam);
        }
        const rol = window.localStorage.getItem("rol");
        var params_login = "";
        if (code !== null) {
          if (mode !== null && mail !== null) {
            params_login = `?plt=sydi&code=${code}&rol=${rol}&mode=${mode}&mail=${mail}`;
          } else {
            params_login = `?plt=sydi&code=${code}&rol=${rol}`;
          }
          setLogging(true);
          try {
            const res = await getRequest(params_login, false, true);

            if (res.s === "OK") {
              window.localStorage.setItem(
                "token_syllabus",
                res["d"]["data"]["token"]
              );

              let myUrl = window.location.href;
              let inicioParametros = myUrl.indexOf("?");
              let newUrl = myUrl.substr(0, inicioParametros);

              if (rol === "administrador" || rol === "evaluador") {
                const path = window.location.pathname.endsWith("/")
                  ? "admin"
                  : "/admin";
                window.location.href = window.location.pathname + path;
              } else if (rol === "docente") {
                window.location.reload();
              } else if (newUrl !== res["d"]["data"]["reload_url"]) {
                window.location.href = res["d"]["data"]["reload_url"];
              } else {
                window.location.reload();
              }
            } else {
              window.localStorage.removeItem("token_syllabus");
              let myUrl = window.location.href;
              let inicioParametros = myUrl.indexOf("?");
              let newUrl = myUrl.substr(0, inicioParametros);
              window.history.pushState("obj", "newtitle", newUrl);
              toast.error(res.m);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
              setLogging(false);
            }
          } catch (error) {
            toast.error("Error en request");
            let myUrl = window.location.href;
            let inicioParametros = myUrl.indexOf("?");
            let newUrl = myUrl.substr(0, inicioParametros);

            window.history.pushState("obj", "newtitle", newUrl);
            setTimeout(() => {
              window.location.reload();
            }, 2000);

            window.localStorage.removeItem("token_syllabus");
            setLogging(false);
          }
        }
      }
    })();
  }, [history]);

  async function login(rol) {
    window.localStorage.setItem("rol", rol);
    setLogging(true);
    try {
      const res = await getRequest(`?plt=sydi&token=&rol=${rol}`, false, true);
      if (res.s === "OK") {
        window.location.href = res["d"]["data"]["url_login"];
      } else {
        setLogging(false);
        toast.error(res.m);
      }
    } catch (error) {
      setLogging(false);
      toast.error(error);
    }
  }

  return (
    <>
      {logged ? (
        <>
          <IndicadoresProvider>
            <MateriaProvider>
              <Header username={nombre} image={image} />
              <Switch>
                <Route path="/details" component={Details}></Route>
                <Route path="/dashboard">
                  <Dashboard teacherKey={teacherKey} />
                </Route>
              </Switch>
              <Footer version={version} />
            </MateriaProvider>
          </IndicadoresProvider>
        </>
      ) : (
        <>
          <LoginPage login={login} loading={logging} />
          <Footer version={version} />
        </>
      )}
    </>
  );
}
