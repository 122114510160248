import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./dataTables.css";

export default function DataTables(props) {
  const data = props.data;
  const [orderBy, setOderBy] = useState({ col: 0, asc: true });
  const [rowsPerPage, setRowsPerPage] = useState(data.length);
  const [pageActual, setPageActual] = useState(props.lastPage || 0);
  const [resize, setResize] = useState(false);
  const tableRef = useRef(null);
  let i = 0;

  if (orderBy.asc) {
    data.sort((a, b) => {
      if (a[orderBy.col] > b[orderBy.col]) {
        return 1;
      }
      if (a[orderBy.col] < b[orderBy.col]) {
        return -1;
      }
      return 0;
    });
  } else {
    data.sort((a, b) => {
      if (a[orderBy.col] < b[orderBy.col]) {
        return 1;
      }
      if (a[orderBy.col] > b[orderBy.col]) {
        return -1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (props.paginate) {
      let height = tableRef.current.clientHeight;
      let newRows = Math.trunc(height / 35);
      setRowsPerPage(newRows);
    }
  }, [data, props.paginate, resize]);

  useEffect(() => {
    const handleResize = () => {
      setResize((prev) => !prev);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getRows() {
    let rows = [];
    let maxLength =
      pageActual < Math.ceil(data.length / rowsPerPage) - 1
        ? rowsPerPage * pageActual + rowsPerPage
        : data.length;
    if (data.length > 0) {
      for (i = rowsPerPage * pageActual; i < maxLength; i++) {
        rows.push(
          <TableRow key={data[i][0]}>
            {data[i].map((cell) => (
              <TableCell key={cell}>{cell}</TableCell>
            ))}
          </TableRow>
        );
      }
    } else {
      return null;
    }
    return rows;
  }

  function getPaginate() {
    let buttons = [];
    buttons.push(
      <button
        key={"Anterior"}
        onClick={() => {
          if (pageActual > 0) {
            setPageActual(pageActual - 1);
            if (props.setLastPage) {
              props.setLastPage(pageActual - 1);
            }
          }
        }}
      >
        Anterior
      </button>
    );

    if (Math.ceil(data.length / rowsPerPage) > 6) {
      if (pageActual <= 2) {
        for (let i = 0; i < 3; i++) {
          buttons.push(
            <button
              key={i + 1}
              onClick={() => {
                setPageActual(i);

                if (props.setLastPage) {
                  props.setLastPage(i);
                }
              }}
              className={pageActual === i ? "active" : null}
            >
              {i + 1}
            </button>
          );
        }
        buttons.push(<button key={"..."}>...</button>);
      } else if (pageActual >= Math.ceil(data.length / rowsPerPage) - 3) {
        buttons.push(<button key={"..."}>...</button>);
        for (
          let i = Math.ceil(data.length / rowsPerPage) - 3;
          i < Math.ceil(data.length / rowsPerPage);
          i++
        ) {
          buttons.push(
            <button
              key={i + 1}
              onClick={() => {
                setPageActual(i);
                if (props.setLastPage) {
                  props.setLastPage(i);
                }
              }}
              className={pageActual === i ? "active" : null}
            >
              {i + 1}
            </button>
          );
        }
      } else {
        buttons.push(<button>...</button>);
        for (let i = pageActual - 2; i < pageActual + 3; i++) {
          buttons.push(
            <button
              key={i + 1}
              onClick={() => {
                setPageActual(i);
                if (props.setLastPage) {
                  props.setLastPage(i);
                }
              }}
              className={pageActual === i ? "active" : null}
            >
              {i + 1}
            </button>
          );
        }
        buttons.push(<button key={"..."}>...</button>);
      }
    } else {
      for (let i = 0; i < Math.ceil(data.length / rowsPerPage); i++) {
        buttons.push(
          <button
            key={i + 1}
            onClick={() => {
              setPageActual(i);
              if (props.setLastPage) {
                props.setLastPage(i);
              }
            }}
            className={pageActual === i ? "active" : null}
          >
            {i + 1}
          </button>
        );
      }
    }
    buttons.push(
      <button
        key={"Siguiente"}
        onClick={() => {
          if (pageActual < data.length / rowsPerPage - 1) {
            setPageActual(pageActual + 1);
            if (props.setLastPage) {
              props.setLastPage(pageActual + 1);
            }
          }
        }}
      >
        Siguiente
      </button>
    );

    return <div className="contenedor-buttons">{buttons}</div>;
  }

  return (
    <>
      <div
        ref={tableRef}
        style={
          props.paginate
            ? {
                height: "calc(100% - 60px)",
                overflow: "hidden",
                position: "relative",
              }
            : { height: "100%", overflow: "auto", position: "relative" }
        }
      >
        <Table stickyHeader className="table-dash">
          <TableHead>
            <TableRow>
              {props.headers.map((header) => {
                return (
                  <TableCell
                    key={header}
                    className="HeadCell"
                    col={i++}
                    onClick={(e) => {
                      let value = parseInt(e.target.getAttribute("col"));
                      if (value === orderBy.col) {
                        setOderBy({ col: orderBy.col, asc: !orderBy.asc });
                      } else {
                        setOderBy({ col: value, asc: true });
                      }
                    }}
                  >
                    {header}
                    <div className="HeadArrows">
                      <ArrowDropUpIcon
                        style={
                          orderBy.col === i - 1 && orderBy.asc
                            ? { color: "red" }
                            : null
                        }
                      />
                      <ArrowDropDownIcon
                        style={
                          orderBy.col === i - 1 && !orderBy.asc
                            ? { color: "red" }
                            : null
                        }
                      />
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {props.loading ? null : <TableBody>{getRows()}</TableBody>}
        </Table>
        {props.loading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              position: "absolute",
              width: "100%",
            }}
          >
            <CircularProgress size={50} />
          </div>
        ) : null}
        {data.length === 0 && !props.loading ? (
          <p
            style={{ textAlign: "center", width: "100%", position: "absolute" }}
          >
            No hay registros que mostrar.
          </p>
        ) : null}
      </div>
      {props.paginate ? getPaginate() : null}
    </>
  );
}
