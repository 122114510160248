let URL_BASE = "https://dldp3b78cb.execute-api.us-east-1.amazonaws.com/test";
let URL_DUMMY = "https://dldp3b78cb.execute-api.us-east-1.amazonaws.com/test";
export const URL_EVAL =
  "https://b35fhrn7kl.execute-api.us-east-1.amazonaws.com/test";
const URL_SESSION_OPENID =
  "https://e8klvcfqt0.execute-api.us-east-1.amazonaws.com/test/sesion";

export const version = "0.0.1";
export const url_SAML = "https://uvmsaml.tk/?source=syllabus&mode=develop";
export const url_ayuda = "https://www.google.com";

export let url_script_ayuda =
  "https://static.zdassets.com/ekr/snippet.js?key=b3f90d0c-ebfb-4de3-b4b9-7fa276e2bd04";

export async function getRequest(ws, dummy = false, session = false) {
  let token = localStorage.getItem("token_syllabus");
  let url = "";
  if (dummy) {
    url = URL_DUMMY;
  } else if (session) {
    url = URL_SESSION_OPENID;
  } else {
    url = URL_BASE;
  }
  let response = await fetch(url + ws, {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tk: token,
    },
  });
  let data = await response.json();
  if (data.s === "error") {
  }
  if (data.m === "token no existe" || data.m === "token expirado") {
    localStorage.removeItem("token_syllabus");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    return data;
  } else {
    return data;
  }
}

export async function postRequest(ws, json = {}, dummy = false) {
  let token = localStorage.getItem("token_syllabus");
  let url = dummy ? URL_DUMMY : URL_BASE;
  let response = await fetch(url + ws, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      tk: token,
    },
    body: JSON.stringify(json),
  });
  let data = await response.json();
  if (data.s === "error") {
  }
  if (data.m === "token no existe" || data.m === "token expirado") {
    localStorage.removeItem("token_syllabus");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    return data;
  } else {
    return data;
  }
}

export async function putRequest(ws, body = {}, dummy = false) {
  let token = localStorage.getItem("token_syllabus");
  let response = await fetch(ws, {
    mode: "cors",
    method: "PUT",
    headers: {
      tk: token,
    },
    body: body,
  });
  let data = await response.json();
  if (data.s === "error") {
  }
  if (data.m === "token no existe" || data.m === "token expirado") {
    localStorage.removeItem("token_syllabus");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    return data;
  } else {
    return data;
  }
}

export async function postLogin(ws, json = {}) {
  let response = await fetch(URL_BASE + ws, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  });
  let data = await response.json();
  if (data.m === "token no existe" || data.m === "token expirado") {
    localStorage.removeItem("token_syllabus");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    return data;
  } else {
    return data;
  }
}
