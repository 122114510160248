import React, { createContext, useState } from 'react'

const IndicadoresContex = createContext(null)

function IndicadoresProvider(props){
    const [data, setData] = useState(null)
    const [completados, setCompletados] = useState(null)
    const [tab,setTab] = useState(null)
    const [lastPage,setLastPage] =  useState(null)
    const value = {
        state: {
            data,
            completados,
            tab,
            lastPage
        },
        actions: {
            setData,
            setCompletados,
            setTab,
            setLastPage
        }
    }

    return (
        <IndicadoresContex.Provider value={value}>
            {props.children}
        </IndicadoresContex.Provider>
    )
}

export default IndicadoresContex
export { IndicadoresProvider }