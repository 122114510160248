import React from "react";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Close from "@material-ui/icons/Close";
import "./newModal.css";

export default function NewModal({
  open,
  syllabus,
  width,
  maxWidth,
  title,
  onlyClose,
  handleClose,
  height,
  loading,
  children,
  hideFooter,
  onDownloadAlternateText,
  onDownloadAlternate,
  canDelete,
  onDelete,
  noCancel,
  save,
  cerrarBtn,
  customAcceptText,
  disableAccept,
  handleSubmitDisabled,
  handleSubmit,
  continue: continueText,
  onDownload,
  hideSubmit,
}) {
  return (
    <Modal open={open}>
      <div
        className={`NewModal ${syllabus ? "NewModal-Syllabus" : ""}`}
        style={{ width: width || null, maxWidth: maxWidth }}
      >
        <h3>
          {title}
          <Close
            className="close-modal"
            onClick={onlyClose || handleClose}
            aria-label="Cerrar Modal"
          />
        </h3>
        <div className="NewModal-container" style={{ height: height }}>
          {loading ? (
            <div className="loading-container">
              <CircularProgress size={50} />
            </div>
          ) : (
            <>
              {children}
              {!hideFooter && (
                <div className="NewModal-footer">
                  {onDownloadAlternate && (
                    <button
                      onClick={onDownloadAlternate}
                      className="download-button"
                    >
                      Descargar{" "}
                      {onDownloadAlternateText
                        ? onDownloadAlternateText
                        : "Documento"}
                    </button>
                  )}
                  {canDelete && (
                    <button onClick={onDelete} className="delete-button">
                      Eliminar
                    </button>
                  )}
                  {!noCancel && (
                    <button className="gray-btn" onClick={handleClose}>
                      {save
                        ? "Continuar sin guardar"
                        : cerrarBtn
                        ? "Cerrar"
                        : "Cancelar"}
                    </button>
                  )}
                  {customAcceptText ? (
                    <button
                      className={disableAccept ? "gray-btn" : "black-btn"}
                      disabled={disableAccept && !handleSubmitDisabled}
                      onClick={
                        disableAccept ? handleSubmitDisabled : handleSubmit
                      }
                    >
                      {customAcceptText}
                    </button>
                  ) : onDownload ? (
                    <a href={onDownload} target="_blank" download>
                      <button className="black-btn">Descargar</button>
                    </a>
                  ) : (
                    !hideSubmit && (
                      <button className="black-btn" onClick={handleSubmit}>
                        {save
                          ? "Guardar y Continuar"
                          : continueText
                          ? "Continuar"
                          : "Aceptar"}
                      </button>
                    )
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
