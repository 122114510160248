import React, { useState,useEffect, useContext } from 'react';
import './tabs.css';
import { useHistory } from "react-router-dom";
import MateriaContext from "../Providers/materia"
import { postRequest } from "../Funciones/requester"
import ModalSaveChanges from '../ModalSaveChanges';
import { useSnackbar } from 'notistack';

function calcular_total(parcial){
  let total = 0;
  parcial['criterios_evaluacion']['teoricos']['porcentajes'].forEach(porcentaje => {
    total += parseFloat(porcentaje.split('%')[0]) || 0
  });
  parcial['criterios_evaluacion']['practicos']['porcentajes'].forEach(porcentaje => {
    total += parseFloat(porcentaje.split('%')[0]) || 0
  });
  return total + '%'
}


export default function Tabs(props) {
  const [activeTab, setActiveTab] = useState(props.selectedTab  || props.namesTabs[0][0]);
  const [confirmModal, setConfirmModal] = useState(false)
  const [tabToGo, setTabToGo] = useState(null)
  const [loading, setLoading] = useState(false)
  const context = useContext(MateriaContext)
  const { enqueueSnackbar } = useSnackbar();
  let tabColor = '#E72400';

  let history = useHistory();

  function noSave(){
    if(props.setActiveTab){
      props.setActiveTab(tabToGo)
    }
    setActiveTab(tabToGo)
    history.push(tabToGo)
    context.actions.setChangesCriterios(false)
    context.actions.setChanges(false)
    setConfirmModal(false)
  }

  useEffect(()=>{
    if(props.selectedTab){
      history.push(activeTab)
    }
  },[activeTab, history, props.selectedTab])

  function save(){
    if(activeTab === '/details/planeacion'){
      savePlaneacion()
    }else{
      saveCriterios()
    }
  }

  async function savePlaneacion(){
    context.actions.setPlaneacionAntigua(JSON.parse(JSON.stringify(context.state.planeacion)))

    let sesiones2 = JSON.parse(JSON.stringify(context.state.planeacion.sesiones))
    
    let sesionesArray = []

    for(let i = 0; i < sesiones2.length; i++){
      let temasArray = []
      for(let j = 0; j < sesiones2[i].temas.length; j++){
        if(sesiones2[i].temas[j].tipo === "unidad"){
          temasArray.push(sesiones2[i].temas[j].idOriginal)
        } else {
          temasArray.push(sesiones2[i].temas[j].id)
        }
      }
      sesionesArray.push({
        id: sesiones2[i].id,
        temas: temasArray
      })
    }

    let json = {
      clave_materia: context.state.item.clave,
      clave_docente: context.state.teacherKey,
      sesiones: sesionesArray
    }

    setLoading(true)
    let response = await postRequest('/editar-planeacion', json)
    if(response.s === "OK"){
      setLoading(false)
    }
    
    context.actions.setChanges(false)
    setActiveTab(tabToGo)
    history.push(tabToGo)
    setLoading(false)
    setConfirmModal(false)
  }

  async function saveCriterios(){
    let data = JSON.parse(JSON.stringify(context.state.criterios))
    let can_edit = true

    Object.keys(data).filter(reg => !reg.includes('FINAL')).forEach(key=>{
      let reg = data[key]
      if(calcular_total(reg) !== '100%'){
        can_edit = false
      }
    })

    if(!can_edit){
      enqueueSnackbar('Los totales de las evaluaciones parciales deben ser iguales a 100', {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }
      });
      return
    }
    
    let json = {
      clave_materia:context.state.item.clave,
      clave_docente:context.state.teacherKey,
      nivel:context.state.item.nivel,
      crn:context.state.item.crn,
      estructura:data
    }
    setLoading(true)
    try{
      const response = await postRequest('/criterio-evaluacion/editar',json)
      if(response.s === 'OK'){
        context.actions.setChangesCriterios(false)
        context.actions.setCriterios(JSON.parse(JSON.stringify(data)))
        context.actions.setCriteriosAntiguos(JSON.parse(JSON.stringify(data))) 
        setLoading(false)
        enqueueSnackbar(response.m, {
          variant: 'success',
          anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
          }
        });
      }else{
        setLoading(false)
        enqueueSnackbar(response.m, {
          variant: 'error',
          anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
          }
        });
      }
    }catch (error){
      setLoading(false)
      enqueueSnackbar('Error modificando criterios evaluación', {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }
      });
    }

    context.actions.setChangesCriterios(false)
    setActiveTab(tabToGo)
    history.push(tabToGo)
    setLoading(false)
    setConfirmModal(false)
  }

  return (
    <>
    <div className='dual-tablist'>
    <div className='tabs-list left-tabs'>
        {props.namesTabs.slice(0, props.separete || props.namesTabs.length).map((tab, i) => (
          <div
            title = {tab[1]}
            key={tab[0] + tab[1]}
            className={activeTab === tab[0] ? 'tab active' : 'tab'}
            onClick={() => {
              if(!props.loading){
                if(tab[0] === '/details/planeacion' && context.state.generales == null){
                  return false
                }

                if(activeTab === '/details/planeacion' || activeTab === '/details/criterios'){
                  if(context.state.changes || context.state.changesCriterios){
                    setTabToGo(tab[0])
                    setConfirmModal(true)
                  } else {
                    if(props.setActiveTab){
                      props.setActiveTab(tab[0])
                    }
                    setActiveTab(tab[0]);
                    history.push(tab[0])
                  }
                } else {
                  if(props.setActiveTab){
                    props.setActiveTab(tab[0])
                  }
                  setActiveTab(tab[0]);
                  history.push(tab[0])
                }
              }
            }}
          >
            {tab[1]}
          </div>
        ))}
      </div>
      <div className='tabs-list right-tabs'>
        {props.namesTabs.slice(props.separete || props.namesTabs.length, props.namesTabs.length).map((tab) => (
          <div
            title = {tab[1]}
            key={tab[0]}
            className={activeTab === tab[0] ? 'tab active' : 'tab'}
            onClick={() => {
              if(!props.loading){
                if(tab[0] === '/details/planeacion' && context.state.generales == null){
                  return false
                }

                if(activeTab === '/details/planeacion' || activeTab === '/details/criterios'){
                  if(context.state.changes || context.state.changesCriterios){
                    setTabToGo(tab[0])
                    setConfirmModal(true)
                  } else {
                    
                    if(props.setActiveTab){
                      props.setActiveTab(tab[0])
                    }
                    setActiveTab(tab[0]);
                    history.push(tab[0])
                  }
                } else {
                  
                  if(props.setActiveTab){
                    props.setActiveTab(tab[0])
                  }
                  setActiveTab(tab[0]);
                  history.push(tab[0])
                }
              }
            }}
          >
            {tab[1]}
          </div>
        ))}
      </div>
    </div>
     
      <div className='tab-content'>
            <div className="tab-header" style={{backgroundColor: tabColor}}></div>
            {props.children}
      </div>
      <ModalSaveChanges
        confirmModal = {confirmModal}
        onlyClose = {()=>{setConfirmModal(false)}}
        noSave = {noSave}
        save = {save}
        loading = {loading}
      />
    </>
  );
}