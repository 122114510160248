import React, { useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress'
import MateriaContext from "../../../Utils/Providers/materia"
import "./Estrategias.css";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    margin: "7px 0",
    "&$expanded": {
      margin: "7px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

function Estrategias(props) {
  const [expanded, setExpanded] = useState("panel1");
  const [loading, setLoading] = useState(false)
  const context = useContext(MateriaContext)
  const [data, setData] = useState(context.state.estrategias ? context.state.estrategias : [])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const dataa = []

  useEffect(() => {
    if(data.length === 0){
      if (props.url){
        async function fetchData(){
          let response = await fetch(props.url)
          let data = await response.json()
          context.actions.setEstrategias(data)
          setData(data)
  
          setLoading(false)
        }
  
        fetchData()
      }
    } else {
      setLoading(false)
    }
  }, [])

  function TablaDinamica(row){
    try{
        let tabla_retornar = <table className="table-style">
        <thead>
          <tr>
            <th style={{width: "15%"}}>Indicadores de desempeño</th>
            <th style={{width: "30%"}}>
              Saberes requeridos para el logro de los resultados del aprendizaje
              <tr>
                <th style={{minWidth: "135px"}}>Conocimientos</th>
                <th style={{minWidth: "135px"}}>Habilidades</th>
                <th>Actitudes</th>
              </tr>  
            </th>
            <th>
              Estrategia de enseñanza
              <tr>
                <th>Práctica #</th>
              </tr>
            </th>
            <th style={{width: "25%"}}>
              Experiencias de aprendizaje
              <tr>
                <th style={{width: "128px"}}>Con docente</th>
                <th style={{width: "187px"}}>Independiente</th>
              </tr>
            </th>
            <th>Instrumentos de evaluación</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><p>{row.indicadores}</p></td>
            <td>
              <tr>
                <td>
                  {
                    row.saberes_requeridos.conocimientos.map(conocimiento => (
                      <p>{conocimiento}</p>
                    ))
                  }
                </td>
                <td>
                  {
                    row.saberes_requeridos.habilidades.map(habilidad => (
                      <p>{habilidad}</p>
                    ))
                  }
                </td>
                <td>
                  {
                    row.saberes_requeridos.actitudes.map(actitud => (
                      <p>{actitud}</p>
                    ))
                  }
                </td>
              </tr>
            </td>
            <td>
              {row.estrategia.join('\n')}
            </td>
            <td>
              <tr>
                <td>
                  {row.experiencias.con_docente.map(experiencia => (
                    <p>{experiencia}</p>
                  ))}
                </td>
                <td>
                  {
                    row.experiencias.independientes.map(experiencia => (
                      <p>{experiencia}</p>
                    ))
                  }
                </td>
              </tr>
            </td>
            <td>
              {row.instrumentos_evaluacion.join('\n')}
            </td>
          </tr>
        </tbody>
      </table>
      return tabla_retornar
    }catch{
      const tabla_retornar = <table className="table-style">
      <thead>
        <tr>
          <th style={{width: "15%"}}>Indicadores de desempeño</th>
          <th style={{width: "30%"}}>
            Saberes requeridos para el logro de los resultados del aprendizaje
            <tr>
              <th style={{minWidth: "135px"}}>Conocimientos</th>
              <th style={{minWidth: "135px"}}>Habilidades</th>
              <th>Actitudes</th>
            </tr>  
          </th>
          <th>
            Estrategia de enseñanza
            <tr>
              <th>Práctica #</th>
            </tr>
          </th>
          <th style={{width: "25%"}}>
            Experiencias de aprendizaje
            <tr>
              <th style={{width: "128px"}}>Con docente</th>
              <th style={{width: "187px"}}>Independiente</th>
            </tr>
          </th>
          <th>Instrumentos de evaluación</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Error obteniendo esta tabla
          </td>
        </tr>
      </tbody>
    </table>
    return tabla_retornar
    }
    
  }

  return (
    <div className="container-data-estrategias">
      {
        loading ?
        <div style={{textAlign: 'center', marginTop: 20}}>
          <CircularProgress size={50} />
        </div> :
        data.map(row => {
          if(row.evaluacion === ''){
            return (
              <ExpansionPanel square expanded={expanded === row.semana} onChange={handleChange(row.semana)}>
                <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Semana {row.semana}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <h4>Semana de evaluación</h4>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          } else {
            return (
              <ExpansionPanel
                square
                expanded={expanded === row.semana}
                onChange={handleChange(row.semana)}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>Semana {row.semana}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {TablaDinamica(row)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          }
        })
      }

      {dataa.map((data) => (
        <ExpansionPanel
          square
          expanded={expanded === data.semana}
          onChange={handleChange(data.semana)}
        >
          <ExpansionPanelSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography>Semana {data.semana}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <table className="table-style">
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>Indicadores de desempeño</th>
                  <th style={{ width: "30%" }}>
                    Saberes reequeridos para el logro de los resultados del
                    aprendizaje
                    <tr>
                      <th style={{ minWidth: "135px" }}>Conocimientos</th>
                      <th style={{ minWidth: "133px" }}>Habilidades</th>
                      <th>Actitudes</th>
                    </tr>
                  </th>
                  <th>
                    Estrategia de enseñanza
                    <tr>
                      <th>Práctica#</th>
                    </tr>
                  </th>
                  <th style={{ width: "25%" }}>
                    Experiencias de aprendizaje
                    <tr>
                      <th style={{ width: "128px" }}>Con docente</th>
                      <th style={{ width: "187px" }}>Independiente</th>
                    </tr>
                  </th>
                  <th>Instrumentos de evaluación</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      El alumno conocerá e identificará los principales países
                      que conforman la gastronomía mediterranea, su historia y
                      cultura. Así mismo conocerá el área comprendida por
                      Francia dentro del Mediterráneo, e identificará las
                      técnicas e ingredientes principales para la elaboración de
                      diferentes platillos.
                    </p>
                  </td>
                  <td>
                    <tr>
                      <td>
                        <p>Cocina mediterránea.</p>
                        <p>Caracteríasticas de esta cocina.</p>
                        <p>Principales regiones del Mediterráneo.</p>
                      </td>
                      <td>
                        <p>Análisis de información.</p>
                        <p>Solución de problemas.</p>
                        <p>Aplicación de la teoría.</p>
                        <p>Identificación de conceptos.</p>
                        <p>Utilización de herramientas culinarias.</p>
                      </td>
                      <td>
                        <p>Calidad de ejecución.</p>
                        <p>Actitud de servicio organizado.</p>
                        <p>Gusto por el trabajo en equipo.</p>
                        <p>Responsable con su trabajo.</p>
                      </td>
                    </tr>
                  </td>
                  <td>
                    <p>1e Encuadre: Objetivos académicos de la materia.</p>
                    <p>
                      2e Presentación del docente de los reglamentos externo e
                      interno.
                    </p>
                    <p>
                      3e Presentación de las reglas del EGEL que intervienen en
                      esta materia.
                    </p>
                    <p>4e Criterios de evaluación y asistencia.</p>
                    <p>5e Lineamientos generales del curso.</p>
                    <p>6e Ficha #0 del Manual de Prácticas.</p>
                  </td>
                  <td>
                    <tr>
                      <td>
                        <p>1d Lecturas comentadas.</p>
                        <p>
                          2d Análisis de la información derivada de procesos de
                          investigación previa.
                        </p>
                        <p>
                          3d Presentación de resultados de diversos procesos y
                          momentos de investigación.
                        </p>
                        <p>4d Discusiones grupales.</p>
                        <p>
                          5d Revisión grupal de tareas para aclarar dudas y
                          verificar avances.
                        </p>
                      </td>
                      <td>
                        <p>1i Investigación de campo.</p>
                        <p>
                          2i Lecturas de apoyo. Nota: Las lecturas de apoyo se
                          encuentran en línea y también en la plataforma Google
                          Classroom.
                        </p>
                        <p>3i Investigación documental.</p>
                        <p>4i Tareas investigación de temas específicos.</p>
                        <p>
                          5i Asistir a eventos (conferencias, pláticas,
                          exposiciones, visitas, etc.).
                        </p>
                      </td>
                    </tr>
                  </td>
                  <td>
                    <p>
                      1i 3i 4i Rúbrica o lista de cotejo para evaluar las
                      tareas, los trabajos o la asistencia a eventos.
                    </p>
                    <p>
                      1i-3i Rúbrica o lista de cotejo para evaluar las tareas,
                      los trabajos o la asistencia a eventos.
                    </p>
                    <p>
                      6e Rubrica de evaluación para clases prácticas Kendall.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  );
}

export default Estrategias;
